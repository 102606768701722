@import "../../../../../../styles/mainStyles.scss";

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-top: .5rem;
      .title {
        @include TitleFont;
        display: flex;
        gap: .625rem;
        align-items: center;
        @include MediumFont;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 104%;

        .return {
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            cursor: pointer;
            span {
              text-decoration: underline;
            }
          }
          span {
            @include RegularFont;
            font-size: .75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 114%; /* 12.48px */
          }

        }
      }
      .subtitle {
        @include RegularFont;
        font-size: .75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 104%;
      }
    }
  }

  .body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.25rem;
    width: stretch;
    @include m {flex-direction: column}

    .card {
      height: auto;
      flex-direction: column;
      width: 35%;
      padding: 1rem;
      align-items: flex-start;
      overflow-x: auto;

      .headerTable {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: stretch;
        flex-grow: 1;
        gap: 1rem;
        padding: 0 .25rem;

        > span {
          @include MediumFont;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: 104%;
        }

        .pageSize {
          margin-left: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: .5rem;
          > span {
            @include RegularFont;
            font-size: .75rem;
            line-height: 110%;
            max-width: 4.25rem;
          }
          .dropdown {
            min-width: 3.125rem;
            .head {
              border-radius: .25rem;
              padding: .5rem;
            }
            .body {
              padding: .25rem 0;
              margin: 0;
              overflow-y: hidden;
            }
          }
        }
      }

      @include scrollBar;
      &::-webkit-scrollbar-track:horizontal {
        margin: 0 .75rem;
      }

      @include m {width: stretch}

      &.tableCard {
        width: 65%;
        padding: 1rem 1rem 0;
        @include m {width: stretch}
      }

      table {
        width: 100%;
        border-collapse: collapse;
        min-width: 650px;

        tr {
          line-height: 100%;
          &:nth-of-type(2) {
            td {
              padding-top: .5rem;
            }
          }
          &:last-of-type {
            td {
              padding-bottom: 1rem;
            }
          }
          span {
            @include RegularFont;
            font-size: .75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 104%;
          }

          th {
            line-height: 104%;
            border-bottom: 1px solid rgba(67, 67, 81, 0.20);
            @include dark {border-color: $light-fifty;}
            text-align: start;
            padding-bottom: .5rem;

            &.check {
              width: 0%;
              input {
                margin-right: .5rem;
                vertical-align: middle;
              }
            }
            &.client {
              width: 22.5%;
            }
            &.status {
              text-align: center;
              width: 20%;
            }
            &.description {
              width: 22.5%;
              // padding-left: 1.5rem;
              // @include m {
              //   padding-left: .5rem;
              // }
            }
            &.dueDate {
              width: 22.5%;
            }
            &.amount {
              width: 12.5%;
            }
            // &:last-of-type {
            &.actions {
              text-align: end;
            }
          }
          td {
            padding: .25rem 0;
            text-align: start;

            &.check {
              width: 0%;
              input {
                margin-right: .5rem;
                vertical-align: middle;
              }
            }
            &.client {
              span {
                @include MediumFont;
                font-weight: 700;
                padding: 0 .5rem;
              }
            }
            &.status {
              text-align: center;
              span {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: .25rem .5rem;
                border-radius: .675rem;
                font-size: .675rem;
                width: 50%;
                margin: 0 auto;

                @include m {
                  width: 70%;
                }

                &.confirmed {
                  color: $bg-color;
                  background: $success;
                }
                &.pending_confirmation {
                  background: $yellow;
                }
                &.rejected {
                  color: $bg-color;
                  background: $error;
                }
                &.scheduled {
                  border: 1px solid $yellow;
                }
              }
            }
            &.actions {
              text-align: end;
              // width: 10%;
              span {
                @include RegularFont;
                color: $primary-color;
                text-align: center;
                font-size: .625rem;
                font-style: normal;
                font-weight: 400;
                line-height: 104%; /* 10.4px */
                text-decoration-line: underline;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
          th, td {
            @include s {
              text-align: center;
            }
          }

          &.emptyState {
            section {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: auto;
              gap: .75rem;
              flex-grow: 1;
              padding: 2rem 0;

              span {
                @include MediumFont;
                font-weight: 500;
                font-size: .875rem;
                line-height: 104%;
                color: $gray-fifty;
                @include dark {color: $light-fifty;}
              }
            }
          }
        }
        tfoot {
          border-top: 1px solid rgba(67, 67, 81, 0.20);
          @include dark {border-color: $light-fifty;}
          tr {
            line-height: 100%;
            td {
              padding: 1rem 0 !important;
            }
          }
        }
      }
    }
  }

input[type=checkbox]{
  accent-color: $primary-color;
  color: #fff;
}