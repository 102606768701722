@import "../../../../../../styles/mainStyles.scss";

.card {
  height: auto;
  flex-direction: column;
  width: 35%;
  padding: 1rem;

  .box {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: stretch;

    .header {
      display: flex;
      padding: .5rem 0 .75rem;
      gap: .25rem;
      justify-content: start;
      @include RegularFont;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      border-bottom: 2px solid rgba(67, 67, 81, 0.20);

      span {
        @include MediumFont;
        font-weight: 700;
        }
    }
    .body {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;

      .textWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: .5rem;
        width: 50%;

        &:last-of-type {
          border-left: 1px solid $gray-fifty;
          padding-left: 1rem;
        }

        span {
          @include RegularFont;
          font-size: .75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 104%;

          &.amount {
            @include MediumFont;
            font-size: 1.875rem;
            font-weight: 700;
            color: $primary-color;
            @include m {font-size: 1.5rem;}
          }
        }
      }
    }

    .payButton {
      width: 100%;
      border-radius: .625rem;
      gap: 0.5rem;

      svg {
        path, rect {
          stroke: none;
          fill: setColor($primary-color);
        }
      }

      &:disabled {
        svg {
          path, rect {
            fill: $light-fifty;
            @include dark {fill: $light;}
          }
        }
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}

.outerModal {
  // max-width: 17rem;
  padding: 2rem;
}
.containerModal {
  max-width: 22.5rem;
  gap: 1rem;
}
.successContainerModal {
  max-width: 18rem;
  gap: 1rem;

  .text {
    padding-top: .5rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    span {
      text-align: center;
      @include RegularFont;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 104%; /* 20.8px */
      &.price {
        @include MediumFont;
        font-size: 2.5rem;
        font-weight: 600;
      }
    }
  }
}