@import '../../styles/mainStyles.scss';

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: $white;
  @include dark {background: $dark-light;}

  height: 100%;
  width: 100%;

  border-radius: .625rem;

  &.straightCorners {
    border-radius: .313rem;
  }
}
.shadow {
  box-shadow: 0 .25rem 1.25rem $shadow-color;
}