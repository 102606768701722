@import "../../styles/mainStyles.scss";

.section {
  padding: 1rem 0;
  flex-direction: column;
}
.container, .header {
  width: stretch;
  gap: 1rem;

  @include s {
    padding: 1rem;
  }
  @include xs {
    padding: .75rem .5rem;
  }
}
.header {
  padding-bottom: 0;
}
.firstColumn, .secondColumn {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.firstColumn {
  flex-grow: 1;
}

.transactionsCard{
  flex-direction: column;
  padding: 1.5rem;
  width: stretch;
  background: $bg-color;
  @include dark {background: $dark-light;}
}

.secondColumn {
  width: 32%;
  @include m {
    width: 100%;
  }
}
.hideMobile {
  @include m {
    display: none;
  }
}