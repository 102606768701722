@import "../../styles/mainStyles.scss";

.section {
  position: relative;
  overflow: hidden;
  flex-direction: column;
}

.container {
  width: stretch;
  gap: 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;

  @include m {
    gap: 1.5rem;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: stretch;

    .title {
      @include TitleFont;
      font-size: 2rem;
      font-style: normal;
      font-weight: 300;
      line-height: 104%;

      @include m {
        font-size: 1.75rem;
      }
      @include xs {
        font-size: 1.5rem;
      }
    }
  }
  .socials {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;

    @include m {
      gap: 3rem;
    }
    @include s {
      gap: 3rem;
    }
    @include xs {
      gap: 1rem;
    }
    a {
      text-decoration: none;
    }
  }
}

.logo {
  path {
    fill: $primary-color;
  }
}