@import "../../../../styles/mainStyles.scss";

.container {
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
  padding: 1rem 0 3rem;

  @include m {
    flex-direction: column-reverse;
  }
  @include s {
    padding: .75rem 0 2rem;
  }
  @include s {
    padding: .5rem 0 1rem;
  }
  .firstColumn, .secondColumn {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 50%;
    @include m {
      width: 100%;
      gap: 1.5rem;
    }

    .infoHeader {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @include s {
        gap: .75rem;
        padding-bottom: .75rem;
      }
      @include xs {
        gap: .5rem;
        padding-bottom: .5rem;
      }

      .title {
        display: flex;
        flex-direction: column;
        gap: .25rem;
        span {
          &:first-of-type {
            @include RegularFont;
            font-size: 1.5rem;
            line-height: 104%;
            @include s{
              font-size: 1.25rem;
            }
            @include xs{
              font-size: 1rem;
            }
          }
          &:last-of-type {
            @include TitleFont;
            font-size: 1.875rem;
            line-height: 104%;
            font-weight: 600;
            @include s{
              font-size: 1.5rem;
            }
            @include xs{
              font-size: 1.2rem;
            }
          }
        }
      }
      .subtitle {
        @include MediumFont;
        font-size: 1.25rem;
        line-height: 1.25rem;
        font-weight: 500;
        @include s{
          font-size: 1.1rem;
          line-height: 1.1rem;
        }
        @include xs{
          font-size: 1rem;
          line-height: 1rem;
        }
      }
    }
  }

  .firstColumn {
    .card, .progressCard {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding: 2rem;
      height: auto;
      width: auto;
      @include dark {background: $dark-light;}
    }
    .card {
      @include m {
        gap: 1.75rem;
        padding: 0;
        border-radius: .313rem;
      }
      @include s {
        gap: 1.5rem;
      }
      @include xs {
        gap: 1rem;
      }
      .boxes {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1rem;
        width: 100%;
        height: 100%;

        &.hidden {display: none;}

        .box, .highlight {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: .625rem;
          color: $icon-color;
          max-width: calc(25% - .75rem);
          flex: 0 0 calc(25% - .75rem);
          height: 100%;
          max-height: 7.125rem;
          gap:.5rem;

          &.straightCorners {
            border-radius: .313rem !important;
          }

          @include m {
            min-height: 6.125rem;
            max-height: 7rem;
          }
          @include s {
            max-height: 6.625rem;
          }
          @include xs {
            height: 4.3125rem;
          }

          .value {
            font-size: 2.5rem;
            line-height: 100%;
            font-weight: 400;
            @include l {
              font-size: 2.25rem;
            }
            @include m {
              font-size: 2rem;
            }
            @include s {
              font-size: 1.75rem;
            }
            @include xs{
              font-size: 1.25rem;
            }
          }
          .label {
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: 0em;
            text-align: center;
            @include s{
              font-size: 1rem;
            }
            @include xs{
              font-size: .5rem;
            }
          }
          span {
            font-size: .875rem;
            line-height: 100%;
            font-weight: 400;
            text-align: center;

            @include s {
              font-size: .75rem;
            }
            @include xs {
              font-size: .625rem;
            }
          }
        }
        .box {
          aspect-ratio: 1 / 1;
          background: $ternary-color;
        }
        .highlight {
          max-width: calc(50% - .5rem);
          flex: 0 0 calc(50% - .5rem);
          background: $text-color;
          aspect-ratio: 2 / 1;
          color: $light;
        }
        .skeletonBox {
          background: unset;
          span {
            width: 100%;
            height: 100%;
          }
        }
      }
      .rows {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 2rem;
        width: 100%;
        height: 100%;

        @include m {
          gap: 1.5rem;
          padding: .5rem 0;
        }
        @include s {
          gap: 1rem;
        }

        .featuredRow {
          &:first-of-type {
            .icon { border-color: transparent; }
          }
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: flex-start;
          gap: .75rem;

          .icon {
            flex-shrink: 0;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 100%;
            border: 1px solid $gray;
            background-size: auto;
            background-position: center;
            background-repeat: no-repeat;
            @include s {
              width: 2.25rem;
              height: 2.25rem;
            }
          }
          .text {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            width: 100%;
            height: 100%;
            align-items: flex-start;
            justify-content: space-between;

            span {
              &:first-of-type {
                @include MediumFont;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 700;
                line-height: 104%; /* 20.8px */
                @include s {
                  font-size: 1rem;
                }
              }
              &:last-of-type {
                @include RegularFont;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 20.8px */
                @include s {
                  font-size: .875rem;
                }
              }
            }
          }

        }
      }
      .line {
        border: 1px solid $neutrals5;
        width: 100%;
        margin: .5rem 1rem 0;

        &.hidden {display: none;}

        @include m {
          display: none;
        }
      }
    }
    .progressCard {
      gap: 1rem;
      @include m {
        padding: .5rem 0;
      }
      .header {
        display: flex;
        justify-content: space-between;
        align-items: end;
        width: 100%;
        span {
          &:first-of-type {
            @include MediumFont;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: 104%; /* 20.8px */
            @include s {
              font-size: 1.2rem;
            }
          }
          &:last-of-type {
            @include RegularFont;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: 104%; /* 20.8px */
            @include s {
              font-size: 1rem;
            }
          }
        }
      }
    }
    .infoDescription {
      display: flex;
      flex-direction: column;
      gap: 1.75rem;
      @include s {
        gap: 1.25rem;
      }
      span {
        @include MediumFont;
        font-size: 1.25rem;
        line-height: 100%;
        font-weight: 500;
        @include xs {font-size: 1rem;}
      }
      .description {
        font-weight: 400;
        line-height: 100%;
        text-align: justify;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */

        @include s {
          font-size: .75rem;
        }
        @include xs {
          font-size: .75rem;
        }
      }
    }
  }
  .divider {
    display: none;
    border: 1px solid $neutrals5;
    width: 100%;
    @include m {
      display: block;
    }
  }
  .secondColumn {
    .infoHeader {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      height: auto;

      .column {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @include s{
          gap: .75rem;
        }
        @include xs{
          gap: .5rem;
        }
      }

      .percentage {
        transform: scale(1.6);
        margin-right: 1rem;
        @include s{
          margin-right: .5rem;
          transform: scale(1.4);
        }
        @include xs{
          margin-right: .375rem;
          transform: scale(1.2);
        }
        span {
          font-size: .875rem;
        }
      }
    }
  }
  .buttonRow {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: .5rem;
    width: 100%;

    .linkButton {
      flex: 1;
      text-decoration: none;
      color: unset;

      .button {
        font-size: .875rem;
        padding: .5rem;
        border-radius: .5rem;
        gap: .125rem;
        width: 100%;
        @include RegularFont;

        @include xs {
          padding: .5rem;
          font-size: .75rem;
        }

        .icon {
          min-width: 24px;
          path {
            stroke: $text-color;
          }
        }
      }
    }
  }
}

.kycModal {
  gap: 1.25rem;
}