@import "../../../../styles/mainStyles.scss";

.card {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;
  height: fit-content;
  width: stretch;
  padding: 1rem;
  background: $bg-color;
  z-index: 0;
  @include s {
    width: stretch;
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 2.5rem;
    width: 2.5rem;
    min-height: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    background: $ternary-color;

    svg {
      path { fill: setColor($ternary-color); }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: .375rem;
    span {
      &:first-of-type {
        @include RegularFont;
        font-size: .75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 104%; /* 12.48px */
      }
      &:last-of-type {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        gap: .5rem;

        @include MediumFont;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 104%; /* 12.48px */
        color: $primary-color;

        @include m {font-size: 1.75rem;}

        &.currency {
          @include RegularFont;
          font-size: .938rem;
          line-height: 135%; /* 12.48px */
        }
      }
    }
  }

}