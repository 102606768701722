@import "../../styles/mainStyles.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  width: stretch;
  margin: 0 auto;
  max-width: 30rem;
  padding: 2.5rem 2rem;

  @include s {
    padding: 2rem 1.5rem 4rem;
    gap: 1.5rem;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: .75rem;
    padding: 0 2rem;

    @include s {
      gap: .5rem;
      .title { font-size: 1.25rem; }
      .clarification { font-size: .875rem; }
    }
    .title {
      @include TitleFont;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: 0em;
      text-align: center;

    }
  }

  .clarification {
    font-size: 1rem;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0em;
    text-align: center;
  }

  .formOptionContainer {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 100%;

    .formOption {
      padding: 1px;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      background: $gray-fifty;
      border-radius: .675rem;
      &.highlighted {
        background: transparent;
        .box {
          background: $primary-color;
          .circle {
            background: $bg-color;
            .person {
              path {
                fill: $gray;
              }
            }
          }
          .textContainer {
            background: $primary-color;
            color: setColor($primary-color);
          }
          .arrow {
            line {
              stroke: setColor($primary-color);
            }
          }
        }
      }

      .box {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1.25rem;
        border-radius: .625rem;
        width: auto;
        height: auto;

        background: $bg-color;
        z-index: 500;

        @include xs {
          padding: 1rem;
          height: fit-content;
        }

        .circle {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3.125rem;
          height: 3.125rem;
          border-radius: 100%;
          background: $primary-color;

          @include s {
            width: 3rem;
            height: 3rem;
          }
          @include xs {
            width: 2.5rem;
            height: 2.5rem;
          }
        }
        .textContainer {
          display: flex;
          flex-direction: column;
          gap: .5rem;
          background: $bg-color;
          @include s {
            gap: .25rem;
          }
          > span {
            @include MediumFont;
            font-size: 1.25rem;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: 0em;
            text-align: left;

            @include xs {
              font-size: 1rem;
            }
          }
        }
        .arrow {
          margin-left: auto;
          transform: rotate(90deg);

          line {
            stroke: $gray-fifty;
          }
        }
      }

      &:hover {
        cursor: pointer;
        background: linear-gradient(rgb(0 0 0/5%) 0 0);

        .box {
          background-image: linear-gradient(rgb(0 0 0/5%) 0 0);
          .textContainer {
            background-image: linear-gradient(rgb(0 0 0/5%) 0 0);
          }
        }
        @include dark {
          background: $light;
          .box {
            background-image: $light;
            .textContainer {
              background-image: $light;
            }
          }
        }
      }
    }
  }
}

.person {
  path {
    fill: setColor($primary-color);
  }
}
.company {
  path {
    stroke: setColor($primary-color);
  }
}