@font-face {
  font-family: 'ThinFont';
  src: url('../assets/fonts/Roboto-Thin.ttf');
}
@mixin ThinFont {
  font-family: ThinFont;
}
@font-face {
  font-family: 'RegularFont';
  src: url('../assets/fonts/Roboto-Regular.ttf');
}
@mixin RegularFont {
  font-family: RegularFont;
}
@font-face {
  font-family: 'MediumFont';
  src: url('../assets/fonts/Roboto-Regular.ttf');
}
@mixin MediumFont {
  font-family: MediumFont;
}
@font-face {
  font-family: 'BoldFont';
  src: url('../assets/fonts/Roboto-Medium.ttf');
}
@mixin BoldFont {
  font-family: BoldFont;
  @include xs {
    font-family: MediumFont;
  }
}
@font-face {
  font-family: 'ExtraBoldFont';
  src: url('../assets/fonts/Saira-ExtraBold.ttf');
}
@mixin ExtraBoldFont {
  font-family: ExtraBoldFont;
}
@font-face {
  font-family: 'InterRegular';
  src: url('../assets/fonts/Inter-Regular.ttf');
}
@mixin InterRegular {
  font-family: InterRegular;
}
@font-face {
  font-family: 'CondesedFont';
  src: url('../assets/fonts/SairaCondensed-Regular.ttf');
}
@mixin CondesedFont {
  font-family: CondesedFont;
}
@font-face {
  font-family: 'TitleFont';
  src: url('../assets/fonts/Saira-Medium.ttf');
}
@mixin TitleFont {
  font-family: TitleFont;
}
@font-face {
  font-family: 'ButtonFont';
  src: url('../assets/fonts/Saira-Medium.ttf');
}
@mixin ButtonFont {
  font-family: ButtonFont;
}