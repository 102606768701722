@import "../../../../styles/mainStyles.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  transition: .6s;
  -webkit-animation: fadeIn .6s;
  animation: fadeIn .6s;

  .label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .625rem;
    margin: 0 auto;
    width: 80%;
    border-radius: .625rem;
    background: rgba(28, 74, 118, 0.70);

    @include RegularFont;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0em;
    color: $white;
  }
  .metamap {
    display: flex;
    height: 41.5rem;
    overflow: visible;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    background-color: transparent;
    width: 65%;
    width: 400px;
    margin: 0 auto;
    background: $bg-color;
  }
  .blank {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 3.75rem;
    right: 0;
    left: 0;
    z-index: 1;
    width: 65%;
    width: 400px;
    margin: 0 auto;
    .card {
      display: flex;
      width: 3rem;
      height: 4rem;
      align-content: flex-end;
      justify-content: flex-end;
      margin-left: auto;
      background: $bg-color;
    }
  }

  @media only screen and (max-width: 435px) {
    .metamap, .blank {
      margin: 0 -.5rem;
      width: 105%;
    }
    .label {
      margin: 0 1rem;
    }
  }

  metamap-button {
    display: none;
  }
}