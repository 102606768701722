@import '../../styles/mainStyles.scss';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .825rem;
  height: 2.5rem;
  min-height: 2.5rem;
  border-radius: $default-button-border-radius;
  padding: 0 1rem;
  position: relative;

  box-shadow: none;
  border: none;
  border-color: transparent;

  @include ButtonFont;
  font-size: .875rem;
  font-weight: 500;
  line-height: 100%;
  span {
    @include ButtonFont;
    font-size: .875rem;
    font-weight: 500;
  }

  &:hover {
    cursor: pointer;
    border: none;
    box-shadow: none;
    outline: none;
  }
}
.button-outline {
  background: $bg-color;
  color: $text-color;
  @include ButtonFont;
  font-size: .875rem;
  font-weight: 500;
  box-shadow: inset 0 0 0 1px $base-color;
  @include dark {background: $dark-light;}

  &:hover {
      box-shadow: none;
      background: darken($white, 10%);
      @include dark {background: darken($white, 60%);}
      -webkit-transition: background 600ms ease-out;
      -ms-transition: background 600ms ease-out;
      transition: background 600ms ease-out;
  }
  &:disabled {
    &:hover {
      box-shadow: inset 0 0 0 1px $base-color;
    }
  }
}
.circle {
  border-radius: 50%;
  padding: 0;
  min-width: 3.5rem;
  min-height: 3.5rem;
}
.background {
  &-primary {
    color: $light;
    background: $primary-color;
    .loader { border-color: setColor($primary-color) }
    svg {
      path {
        stroke: setColor($primary-color);
      }
    }
    &:hover {
      background: darken($primary-color, 10%);
      -webkit-transition: background 600ms ease-out;
      -ms-transition: background 600ms ease-out;
      transition: background 600ms ease-out;
    }
  }
  &-primary-gradient {
    color: $light;
    background: $primary-gradient;
    .loader { border-color: setColor($primary-color) }
    &:hover {
      background: darken($primary-color, 10%);
      -webkit-transition: background 600ms ease-out;
      -ms-transition: background 600ms ease-out;
      transition: background 600ms ease-out;
    }
  }
  &-secondary {
    color: setColor($secondary-color);
    background: $secondary-color;
    .loader { border-color: setColor($secondary-color) }
    svg {
      path {
        stroke: setColor($secondary-color);
      }
    }
    &:hover {
      background: darken($secondary-color, 10%);
      -webkit-transition: background 600ms ease-out;
      -ms-transition: background 600ms ease-out;
      transition: background 600ms ease-out;
    }
  }
  &-light {
    background: $light;
    .loader { border-color: setColor($light) }
    &:hover {
      background: darken($light, 10%);
      -webkit-transition: background 600ms ease-out;
      -ms-transition: background 600ms ease-out;
      transition: background 600ms ease-out;
    }
  }
  &-white {
    background: $white;
    .loader { border-color: setColor(white) }
    &:hover {
      background: darken($white, 10%);
      -webkit-transition: background 600ms ease-out;
      -ms-transition: background 600ms ease-out;
      transition: background 600ms ease-out;
    }
  }
  &-transparent {
    background: transparent;
  }
  &-base {
    background: $base-color;
    .loader { border-color: setColor($base-color) }
    &:hover {
      background: darken($base-color, 10%);
      -webkit-transition: background 600ms ease-out;
      -ms-transition: background 600ms ease-out;
      transition: background 600ms ease-out;
    }
  }
  &-gray {
    background: $gray-fifty;
    color: setColor($gray-fifty);
    .loader { border-color: setColor($gray-fifty) }
    &:hover {
      background: darken($gray-fifty, 10%);
      -webkit-transition: background 600ms ease-out;
      -ms-transition: background 600ms ease-out;
      transition: background 600ms ease-out;
    }
  }
}
.border {
  &-primary {
    box-shadow: inset 0 0 0 1px $primary-color;
  }
  &-secondary {
    box-shadow: inset 0 0 0 1px $secondary-color;
  }
  &-light {
    box-shadow: inset 0 0 0 1px $light;
  }
  &-white {
    box-shadow: inset 0 0 0 1px white;
  }
  &-transparent {
    box-shadow: inset 0 0 0 1px transparent;
  }
  &-dark {
    box-shadow: inset 0 0 0 1px $dark-light;
    &:hover {
      box-shadow: inset 0 0 0 1px $dark-light;
    }
  }
  &-base {
    box-shadow: inset 0 0 0 1px $base-color;
    &:hover {
      box-shadow: inset 0 0 0 1px $base-color;
    }
  }
}
.loading {
  > span {
    color: transparent;
  }
  svg {
    display: none;
  }
  &:hover {
    cursor: not-allowed;
    opacity: 1;
  }
}
.disabled {
  &:not(.loading) {
    background: #608EAF  !important;
    color: setColor(#608EAF ) !important;
    opacity: 1 !important;

    @include dark {
      background: $gray !important;
      color: setColor($gray) !important;
    }

    &:hover {
      cursor: not-allowed;
      opacity: 1 !important;
    }
  }
}

.callToAction {
  color: setCallToActionColor($call-to-action-button-color);
  background: $call-to-action-button-color;
  .loader { border-color: setCallToActionColor($call-to-action-button-color) }
  svg {
    path {
      stroke: setCallToActionColor($call-to-action-button-color);
    }
  }
  &:hover {
    background: darken($call-to-action-button-color, 10%);
    color: setColor(darken($call-to-action-button-color, 10%));
    -webkit-transition: background 600ms ease-out;
    -ms-transition: background 600ms ease-out;
    transition: background 600ms ease-out;
    svg {
      path {
        stroke: setColor(darken($call-to-action-button-color, 10%));
      }
    }
  }
}

.loader {
  position: absolute;
  top: 25%;
  left: 45%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
}