@import "../../../../../../styles/mainStyles.scss";

.title {
  @include TitleFont;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 104%;
  letter-spacing: 0em;
  text-align: center;

  @include xs {
    font-size: 1.25rem;
  }
}
.price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .5rem;

    .value {
      @include BoldFont;
      font-size: 2.5rem;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0em;
    }
    .currency {
      @include RegularFont;
      font-size: 1.875rem;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0em;
      text-transform: uppercase;
      margin-top: .25rem;
    }
  }
  .label, .officialCurrency {
    @include RegularFont;
    font-weight: 400;
    line-height: 105%;
    letter-spacing: 0em;
  }
  .label {
    font-size: 1.25rem;
  }
}
.properties {
  display: flex;
  flex-direction: column;
  gap: .75rem;
  padding-bottom: .75rem;
  border-bottom: 1px solid $neutrals5;

  .property, .total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include RegularFont;
    font-size: 1rem;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: 0em;

    span {
      @include BoldFont;
      font-weight: 600;
      &.strikethrough {
        @include MediumFont;
        font-weight: 500;
        color: $gray-fifty;
        @include dark {color: $light-fifty;}
        text-decoration: line-through;
      }
      &.free {
        color: $success;
      }
    }
  }
  .gas {
    display: flex;
    gap: .125rem;
    @include RegularFont;
    font-size: .75rem;
    font-weight: 400;
    line-height: 104%;
    letter-spacing: -0.01em;
    text-align: left;

    a {
      text-decoration-line: underline;
      color: $text-color;
      &:hover {
        font-weight: 600;
      }
    }
  }
}
.total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include MediumFont;
  font-size: 1rem;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: 0em;
  margin-top: -.25rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $neutrals5;

  span {
    @include BoldFont;
    font-weight: 600;
    &.strikethrough {
      @include MediumFont;
      font-weight: 500;
      color: $gray-fifty;
      @include dark {color: $light-fifty;}
      text-decoration: line-through;
    }
    &.free {
      color: $success;
    }
  }
}
.paymentMethod {
  display: flex;
  flex-direction: column;
  gap: .75rem;
  padding-bottom: .75rem;
  border-bottom: 1px solid $neutrals5;

  .label {
    @include BoldFont;
    font-size: 1rem;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0em;
    text-align: start;
  }
  .method {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include RegularFont;
    font-size: 1rem;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0em;

    span {
      display: flex;
      gap: .5rem;
      align-items: center;

      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;

        svg {
          width: .875rem;
          height: .875rem;
          transform: scale(1);
        }
      }
    }
    .changeMethod {
      display: flex;
      gap: .25rem;
      font-size: .875rem;
      align-items: center;
      color: $primary-color;

      &:hover {
        cursor: pointer;
        color: darken($primary-color, 2);
        text-decoration: underline;
      }
    }
  }
}
.footer {
  padding-top: 2rem;
  display: flex;
  @include RegularFont;
  font-size: .75rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}
.terms {
  display: inline-block;
  gap: .125rem;
  width: 100%;

  @include RegularFont;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.01rem;
  color: $text-color;

  .link {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.01rem;
    text-decoration-line: underline;
    color: $text-color;

    &:hover {
      cursor: pointer;
      color: $gray;
      @include dark {color: $light;}
    }
  }
}
.button {
  margin-top: -.5rem;
}
.warning {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  transition: .8s;
  -webkit-animation: fadeIn .8s;
  animation: fadeIn .8s;
}
.redirect {
  text-decoration: underline;
  color: $primary-color;
  &:hover {cursor:pointer;}
}
.text {
  display: flex;
  flex-direction: column;
  gap: .25rem;
}