@import "../../../../../../styles/mainStyles.scss";

.card {
  height: auto;
  flex-direction: column;
  padding: 1rem;

  .box {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: stretch;

    .header {
      display: flex;
      padding: .5rem 0 .75rem;
      gap: .25rem;
      justify-content: start;
      @include RegularFont;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      border-bottom: 2px solid rgba(67, 67, 81, 0.20);

      span {
        @include MediumFont;
        font-weight: 700;
        }
    }
    .body {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;

      .textWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: .5rem;
        flex-grow: 1;

        span {
          @include RegularFont;
          font-size: .75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 104%;

          &.amount {
            font-size: 1.875rem;
            font-weight: 700;
            color: $primary-color;
            @include m {font-size: 1.5rem;}
          }
        }
      }
    }

    .reserveButton {
      width: 100%;
      border-radius: .625rem;
      gap: 0.25rem;

      &:disabled {
        svg {
          path {
            fill: $light-fifty;;
          }
        }
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}

.confirmationContainerModal {
  max-width: 25rem;
}
.outerModal {
  padding: 2rem;
}
.successOuterModal {
  padding: 2rem 1rem;
}
.containerModal {
  max-width: 22.5rem;
  gap: 1rem;
}
.successContainerModal {
  max-width: 21rem;
  gap: 1rem;

  .text {
    padding-top: .5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .description {
      text-align: center;
      @include RegularFont;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      > span {
        font-weight: 700;
      }
    }

    > span {
      text-align: center;
      @include RegularFont;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 104%; /* 20.8px */
      &.price {
        @include MediumFont;
        font-size: 2.5rem;
        font-weight: 600;
      }
    }
  }
}

input[type=checkbox]{
  accent-color: $primary-color;
  color: #fff;
}