@import "../../../../../../../styles/mainStyles.scss";

.body {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding: .5rem;
  height: auto;
  width: stretch;
  border-radius: .675rem;
  transition: all $animation-timing-default;

  .main, .detail {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: .75rem;
    @include RegularFont;
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: 0em;
    font-style: normal;
    line-height: normal;
    transition: .5s;
  }
  .main {
    span {
      &:first-of-type {
        margin-right: auto;
      }
    }
  }
  .detail {
    padding: .5rem 0;
    border-bottom: .5px solid $gray-fifty;

    &:first-of-type {
      padding-top: 0;
    }
    &:last-of-type {
      border: none;
      padding-bottom: 0;
    }

    span {
      margin-left: auto;
      @include MediumFont;
      font-weight: 600;
    }
  }

  .details, .line {
    display: none;
    width: 100%;
  }
  .details {
    transform: translateY(3px);
    visibility: hidden;
  }

  .copy, .arrow {
    &:hover { cursor: pointer; }
  }
  .copy {
    path {fill: $primary-color;}
  }
  .arrow {
    line { stroke: $primary-color;}
  }
  .copied {
    text-transform: capitalize;
    animation: fadeIn .6s;
    font-weight: 600;
    color: $success;
  }

  &:hover {
    transition: background .6s ease-in-out;
    background: #E8E8E7;
  }

  &.expanded {
    &:hover {
      background: unset;
    }
    .arrow {
      transition: transform $animation-timing-default;
      transform: rotate(180deg);
    }
    .line {
      display: block;
      border: 1px solid $gray-fifty;
    }
    .details {
      display: flex;
      flex-direction: column;
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
      animation: fadeIn .6s;
    }
  }
}
