@import "../../../../../../styles/mainStyles.scss";

.title {
  @include TitleFont;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 104%;
  letter-spacing: 0em;
  text-align: center;

  @include xs {
    font-size: 1.25rem;
  }
}
.price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .5rem;

    .value {
      @include BoldFont;
      font-size: 2.5rem;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0em;
    }
    .currency {
      @include RegularFont;
      font-size: 1.875rem;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0em;
      text-transform: uppercase;
      margin-top: .25rem;
    }
  }
  .officialCurrency {
    @include RegularFont;
    font-size: 1rem;
    font-weight: 400;
    line-height: 105%;
    letter-spacing: 0em;
  }
}
.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}
.properties {
  display: flex;
  flex-direction: column;
  gap: .75rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $neutrals5;

  .property {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include RegularFont;
    font-size: 1rem;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: 0em;

    span {
      @include BoldFont;
      font-weight: 600;
    }
  }
}
.paymentMethod {
  display: flex;
  flex-direction: column;
  gap: .75rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $neutrals5;

  .label {
    @include BoldFont;
    font-size: 1rem;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0em;
    text-align: start;
  }
  .method {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include RegularFont;
    font-size: 1rem;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0em;

    span {
      display: flex;
      gap: .5rem;
      align-items: center;

      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;

        img {
          width: 1.125rem;
          height: 1.125rem;
          transform: scale(1.5);
        }
      }
    }
    .changeMethod {
      display: flex;
      gap: .25rem;
      font-size: .875rem;
      align-items: center;
      color: $primary-color;

      &:hover {
        cursor: pointer;
        color: darken($primary-color, 2);
        text-decoration: underline;
      }
    }
  }
}
.footer {
  display: flex;
  @include RegularFont;
  font-size: .75rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}
.terms {
  display: inline-block;
  gap: .125rem;
  width: 100%;

  @include RegularFont;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.01rem;
  color: $text-color;

  .link {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.01rem;
    text-decoration-line: underline;
    color: $text-color;

    &:hover {
      cursor: pointer;
      color: $gray;
      @include dark {color: $light;}
    }
  }
}
.description {
  @include RegularFont;
  font-size: .875rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0em;
  text-align: center;
  width: 80%;

  @include xs {
    font-size: .75rem;
  }
}
.outerModal {
  padding: 1.5rem;
}
.containerModal {
  max-width: 19rem;
}
.pending {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;

  > svg {
    path {
      stroke: $primary-color;
    }
  }

  > span {
    @include RegularFont;
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 104%;
    text-transform: uppercase;
    color: $primary-color;
  }
}
.mercadoPago {
  transition: 1s;
  animation: none;
  .return {
    display: flex;
    align-items: center;
    width: stretch;
    &:hover {
      cursor: pointer;
      span {
        text-decoration: underline;
      }
    }
    span {
      @include RegularFont;
      font-size: .75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 114%; /* 12.48px */
    }
  }
  .payment {
    display: none;
    &.visible {
      display: block;
    }
  }
  .skeleton {
    display: none;
    padding: 1rem;
    &.visible {
      display: flex;
      flex-direction: column;
    }

    h1 {
      font-size: 1.125rem;
      width: 100%;
      text-align: left;
    }
    .cards {
      border: 1px solid #E5E5E5;
      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 1rem;
      position: relative;
      .card {
        border: none;
        padding: 1rem;
        width: 100%;
        text-align: left;
        outline: none;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        > span {
          height: min-content !important;
        }

        &:first-of-type {
          border-bottom: 1px solid #E5E5E5;
        }
        .avatar {
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 1rem;
        }
        .text {
          display: flex;
          flex-direction: column;
          font-size: 1rem;
          width: 100%;

          span {
            padding-top: 2px;
            font-size: .875rem;
            width: 100%;
          }
        }
        .button {
          border-radius: 2px;
          width: 100%;
        }
      }
    }
  }
}