@import "../../../styles/mainStyles.scss";

.subtitle {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  align-items: center;
  > span {
    &:first-of-type {
      @include RegularFont;
      font-weight: 400;
      line-height: 104%;
      letter-spacing: 0em;
      text-align: center;

      @include xs {
        font-size: .875rem;
      }
    }
    &:last-of-type {
      @include RegularFont;
      font-size: .75rem;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0em;
      a {
        @include MediumFont;
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }
}
.form {
  display: flex;
  flex-direction: column;
  width: stretch;
  gap: 1rem;
  min-height: 18rem;
}
.dropdown {
  flex-grow: 0;
}
.button {
  margin-top: auto;
  width: 100%;
}
.uploader {
  padding: 1.75rem 0;
  > div {
    flex-direction: column;
    > div {
      align-items: center !important;
    }
  }
}
.circle, .add {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #E6E5E5;
}
.add {
  margin: 0 auto;
  width: 30px;
  height: 30px;
  background: $primary-color;
  &:hover {cursor: pointer;}
  svg {
    width: 10px;
    height: 10px;
    path {
      fill: $white;
      fill-opacity: 1;
    }
  }
}
.files {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: stretch;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: .5rem;
    padding: .125rem .25rem;
    border: 1px solid #DBDBDB;
    border-radius: .25rem;
    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .5rem;
      font-size: .75rem;
      font-weight: 400;
      line-height: 104%;
      &:last-of-type {
        margin-left: auto;
        color: $primary-color;
        &:hover { cursor: pointer;}
      }
    }
  }
}
.acceptedFiles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: stretch;
  gap: .5rem;
  > span {
    @include MediumFont;
    font-size: .75rem;
    font-weight: 500;
    line-height: 104%;
  }
  ol {
    margin: 0;
    padding-left: 1rem;
    font-size: .75rem;
    > li {
      color: $gray;
      @include dark {color: $light;}
      line-height: 130%;
    }
  }
}