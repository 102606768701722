@import "../../../../styles/mainStyles.scss";

.section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: .75rem;
      padding-top: .5rem;
      .title {
        @include TitleFont;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 104%;
      }
      .subtitle {
        font-size: .75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 104%;
      }
      .items {
        display: flex;
        gap: .25rem;
        justify-content: start;
        @include RegularFont;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        > span {
          @include MediumFont;
          font-weight: 700;
        }
      }
    }
    .exchange {
      path {
        stroke: none;
        fill: setColor($primary-color);
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.25rem;
    width: stretch;

    .items {
      display: flex;
      gap: .25rem;
      justify-content: start;
      @include RegularFont;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      > span {
        @include MediumFont;
        font-weight: 700;
      }
    }

    .card {
      height: auto;
      flex-direction: column;
      gap: 1rem;
      width: stretch;
      padding: 1rem 1rem 0;
      align-items: flex-start;
      overflow-x: auto;

      @include s {
        padding: .75rem .5rem 0;
      }

      .headerTable {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: stretch;
        flex-grow: 1;
        gap: 1rem;
        padding: 0 .25rem;

        > span {
          @include MediumFont;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: 104%;
        }

        .pageSize {
          margin-left: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: .5rem;
          > span {
            @include RegularFont;
            font-size: .75rem;
            line-height: 110%;
            max-width: 4.25rem;
          }
          .dropdown {
            min-width: 3.125rem;
            .head {
              border-radius: .25rem;
              padding: .5rem;
            }
            .body {
              padding: .25rem 0;
              margin: 0;
              overflow-y: hidden;
            }
          }
        }
      }

      @include scrollBar;
      &::-webkit-scrollbar-track:horizontal {
        margin: 0 .75rem;
      }
      table {
        width: stretch;
        border-collapse: collapse;
        min-width: 650px;
        tr {
          line-height: 100%;
          &:nth-of-type(2) {
            td {
              padding-top: .5rem;
            }
          }
          &:last-of-type {
            td {
              padding-bottom: 1rem;
            }
          }
          span {
            @include RegularFont;
            font-size: .75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 104%;
          }

          th {
            line-height: 104%;
            border-bottom: 1px solid rgba(67, 67, 81, 0.20);
            @include dark {border-color: $light-fifty;}
            text-align: start;
            padding-bottom: .5rem;

            &:first-of-type, &:last-of-type {
              width: 0%;
            }
            &.check {
              input {
                margin-right: .5rem;
                vertical-align: middle;
              }
            }
          }
          td {
            padding: .25rem 0;
            text-align: start;
            &:last-of-type {
              text-align: center;
            }
            &.check {
              width: 0%;
              input {
                margin-right: .5rem;
                vertical-align: middle;
              }
            }
            &.name {
              span {
                @include MediumFont;
                font-weight: 700;
                line-height: 0;
              }
            }
            &.exchangeStatus {
              text-align: center;
              span {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: .25rem .5rem;
                border-radius: .675rem;
                font-size: .675rem;
                width: 30%;

                &.confirmed {
                  background: $success;
                }
                &.pending_confirmation {
                  background: $yellow;
                }
                &.rejected {
                  color: $bg-color;
                  background: $error;
                }
              }
            }
          }
          th, td {
            @include s {
              text-align: center;
            }
          }
          .downloadLinkButton {
            text-decoration: none;
            color: unset;
            svg {
              vertical-align: middle;
              &:hover {
                cursor: pointer;
              }
              path {
                stroke: $gray;
                stroke-width: 1.2;
                @include dark {stroke: $light;}
              }
            }
          }
        }
        tfoot {
          border-top: 1px solid rgba(67, 67, 81, 0.20);
          @include dark {border-color: $light-fifty;}
          tr {
            line-height: 100%;
            td {
              padding: 1rem 0 !important;
            }
          }
        }
      }
    }
  }
}

.emptyState {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  width: 40%;
  .header {
    @include BoldFont;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 104%;
    text-align: center;
  }
  .footer {
    @include RegularFont;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 104%;

    > span {
      color: $primary-color;
      text-decoration: underline;
      &:hover {cursor: pointer;}
    }
  }
}

input[type=checkbox]{
  accent-color: $primary-color;
  color: #fff;
}