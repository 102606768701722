@import "../../../../styles/mainStyles.scss";

.section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex-grow: 1;
  @include s {
    gap: 1rem;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.5rem;
    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-top: .5rem;
      .title {
        @include TitleFont;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 104%;
      }
      .subtitle {
        @include RegularFont;
        font-size: .75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 104%;
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
      gap: .5rem;

      .left, .right {
        display: flex;
        flex-direction: row;
        gap: .5rem;
      }
      @include s {
        flex-wrap: wrap;
      }
    }

    @include s {
      gap: 1rem;
      flex-direction: column;
    }
  }

  .body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.25rem;
    width: stretch;
    @include m {flex-direction: column}

    .card {
      height: auto;
      flex-direction: column;
      padding: 1rem;
      &:first-of-type {
        padding: 1rem 1rem 0;
      }
      width: 30%;
      align-items: flex-start;
      overflow-x: auto;
      @include m {width: stretch}

      &.tableCard {
        width: 70%;
        @include m {width: stretch}

        @include scrollBar;
        &::-webkit-scrollbar-track:horizontal {
          margin: 0 .75rem;
        }
      }

      table {
        table-layout: auto;
        width: 100%;
        border-collapse: collapse;
        min-width: 750px;

        tr {
          line-height: 100%;
          &:nth-of-type(2) {
            td {
              padding-top: .5rem;
            }
          }
          &:last-of-type {
            td {
              padding-bottom: 1rem;
            }
          }
          span {
            @include RegularFont;
            font-size: .75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 104%;
          }

          th {
            line-height: 104%;
            border-bottom: 1px solid rgba(67, 67, 81, 0.20);
            @include dark {border-color: $light-fifty;}
            text-align: start;
            padding-bottom: .5rem;

            &.check {
              // width: 0%;
              input {
                margin-right: .5rem;
                vertical-align: middle;
              }
            }
            &.client {
              // width: 17%;
            }
            &.status, &.signatureStatus {text-align: center; padding-left: .375rem; padding-right: .375rem;}
            &.reserveDate {
              width: 13%;
            }
            &.amount {
              // width: 15%;
            }
            &:last-of-type {
              // width: 0;
            }
          }
          td {
            padding: .25rem 0;
            text-align: start;

            &.check {
              // width: 0%;
              input {
                margin-right: .5rem;
                vertical-align: middle;
              }
            }
            &.name {
              span {
                @include MediumFont;
                font-weight: 700;
                line-height: 104%;
              }
            }
            &.status, &.signatureStatus {
              text-align: center;
              span {
                display: flex;
                align-items: center;
                margin: 0 auto;
                justify-content: center;
                padding: .25rem .5rem;
                border-radius: .675rem;
                font-size: .675rem;
                width: 60%;

                @include m {
                  width: 70%;
                }
              }
            }
            &.status {
              span {
                &.confirmed {
                  background: $success;
                }
                &.pending_confirmation {
                  background: $yellow;
                }
                &.rejected {
                  color: $bg-color;
                  background: $error;
                }
                &.scheduled {
                  border: 1px solid $yellow;
                }
              }
            }
            &.signatureStatus {
              span {
                &.signed {
                  background: $success;
                }
                &.started, &in_progress {
                  background: $yellow;
                }
                &.declined, &.expired {
                  color: $bg-color;
                  background: $error;
                }
              }
            }

            &:last-of-type {
              text-align: center;
            }
            .downloadLinkButton {
              text-decoration: none;
              color: unset;
              svg {
                vertical-align: middle;
                &:hover {
                  cursor: pointer;
                }
                path {
                  stroke: $gray;
                  stroke-width: 1.2;
                  @include dark {stroke: $light;}
                }
              }
            }
          }
          th, td {
            @include s {
              text-align: center;
            }
          }

          &.emptyState {
            section {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: auto;
              gap: .75rem;
              flex-grow: 1;
              padding: 2rem 0;

              span {
                @include MediumFont;
                font-weight: 500;
                font-size: .875rem;
                line-height: 104%;
                color: $gray-fifty;
                @include dark {color: $light-fifty;}
              }
            }
          }
        }
        tfoot {
          border-top: 1px solid rgba(67, 67, 81, 0.20);
          @include dark {border-color: $light-fifty;}
          tr {
            line-height: 100%;
            td {
              padding: 1rem 0 !important;
            }
          }
        }
      }
    }
  }
}

input[type=checkbox]{
  accent-color: $primary-color;
  color: #fff;
}