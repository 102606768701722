@import "../../styles/mainStyles.scss";

.section {
  position: relative;
  background-image: url('../../assets/images/banner-background.jpg');
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;

  &.second {
    flex-direction: column;
  }

  @include m {
    text-align: center;
  }
}

.container {
  align-items: flex-start;
  gap: 1.75rem;
  width: 100%;
  margin: 7rem auto auto;

  &.second {
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 4rem;
    margin: 4rem auto 2rem;
    padding: 4rem 1.5rem;
    color: $white;

    @include m {
      margin: 3rem auto 1.5rem;
      gap: 3rem;
      svg {
        height: auto;
        width: 80%;
      }
    }
    @include xs {
      gap: 2rem;
      svg {
        width: 90%;
      }
    }
  }

  @include m {
    margin-top: 6rem;
  }
  @include s {
    margin-top: 5rem;
    gap: 1.5rem;
  }
  @include xs {
    margin-top: 3.5rem;
  }
}

.title {
  @include BoldFont;
  font-size: 6rem;
  font-weight: 700;
  color: $light;
  line-height: 100%;
  letter-spacing: -0.01em;
  text-align: left;

  @include m {
    font-size: 5rem;
    line-height: 5rem;
  }
  @include s {
    font-size: 4rem;
    line-height: 4rem;
  }
  @include xs {
    font-size: 3rem;
    line-height: 3rem;
  }

  span {
    color: $primary-color;
  }

  @include m {
    font-size: 5rem;
  }
  @include s {
    font-size: 3.5rem;
  }
  @include xs {
    font-size: 2.5rem;
  }
}

.subtitle {
  @include RegularFont;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 105%;
  color: $light;
  width: 60%;

  &.second {
    @include MediumFont;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    width: 100%;
    letter-spacing: 0;
    color: $white;

    @include m {
      font-size: 1.75rem;
      width: 80%;
    }
    @include s {
      font-size: 1.5rem;
      width: 80%;
    }
    @include xs {
      font-size: 1.25rem;
      width: 100%;
    }
  }

  @include m {
    font-size: 1.75rem;
    width: 80%;
  }
  @include s {
    font-size: 1.5rem;
    width: 80%;
  }
  @include xs {
    font-size: 1.25rem;
    width: 100%;
  }
}

.buttons {
  display: flex;
  gap: 1.125rem;
  width: stretch;
  padding: 0 1rem;

  @include s {
    flex-direction: column;
    padding: 1rem 2rem 0;
  }
  @include xs {
    padding: 1.75rem 0 0;
  }
  .gradientButton {
    position: relative;
    z-index: 5;
    display: flex;
    height: 100%;
    width: 50%;
    gap: 1rem;
    padding: .125rem;
    flex-wrap: wrap;
    background: $secondary-gradient;
    overflow: visible;

    &:hover {
      opacity: 1;
      background: $primary-color;
      -webkit-transition: background 600ms ease-out;
      -ms-transition: background 600ms ease-out;
      transition: background 600ms ease-out;
    }
    @include s {
      width: 100%;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: .5rem;
      flex-wrap: wrap;
      padding: 1.125rem 0;
      width: 100%;
      // height: 100%;
      background: #121C21;
      border-radius: .125rem;

      @include MediumFont;
      font-size: 1.25rem;
      color: $white;
      font-style: normal;
      font-weight: 700;
      line-height: 104%; /* 20.8px */
      text-decoration: none;

      @include s {
        font-size: 1rem;
        padding: 1rem 0;
      }
      @include xs {
        padding: .75rem 0;
      }
      svg {
        height: 23px;
        width: 22px;
      }

      &:hover {
        background: $primary-color;
        -webkit-transition: background 600ms ease-out;
        -ms-transition: background 600ms ease-out;
        transition: background 600ms ease-out;
      }
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background: linear-gradient(180deg, rgb(0, 48, 97) 0%, rgb(0, 117, 234) 100%);
  padding: 2.5rem 0;

  .button {
    @include BoldFont;
    font-size: 1.25rem;
    font-weight: 600;
    padding: 2rem 6rem;

    @include xs {
      @include MediumFont;
      font-size: 1rem;
      padding: 1.5rem 3rem;
    }
  }
}