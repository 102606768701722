@import "../../styles/mainStyles.scss";

.stepperContainer {
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
  width: 100%;
  margin: 0 auto;
  transition: .35s;
  max-width: 25rem;

  .stepsContainer {
    transition: .35s;
    padding: .625rem 1.5rem 2.5rem;
    width: unset;
    @include dark{border: 1px solid $dark-light;}

    &.padding {
      padding: .625rem 2.5rem 2.5rem;
      @include s {
        padding: 1rem 2rem;
      }
      @include xs {
        padding: 0.625rem 1.5rem;
      }
    }

    .stepsRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      transition: .35s;
      width: 100%;

      .stepContainer {
        display: flex;
        align-items: center;
        position: relative;

        .stepNumber {
          transition: .35s;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0px;

          font-size: 1rem;
          font-weight: 400;
          line-height: 1.563;
          letter-spacing: 0em;
          text-align: center;

          width: 3.125rem;
          height: 3.125rem;
          border-radius: 100%;
          background: $gray-fifty;
          color: $text-color;

          svg {
            path {
              fill: $text-color;
            }
          }
        }
        .label {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 3.5rem;
          left: 0;
          width: 100%;
          max-height: 4rem;

          @include MediumFont;
          font-size: .625rem;
          font-weight: 500;
          line-height: .625rem;
          letter-spacing: 0em;
          text-align: center;
          color: $gray-fifty;
          @include dark{color: $light-fifty;}

          @include s {
            display: none;
          }
        }

        &.active {
          .stepNumber {
            background: $primary-color;
            color: setColor($primary-color);
            svg {
              path {
                fill: setColor($primary-color);
              }
            }
          }
          .label {
            color: $text-color;
          }
        }
        &.done {
          .stepNumber {
            background: $secondary-color;
            color: setColor($secondary-color);
            svg {
              path {
                fill: setColor($secondary-color);
              }
            }
          }
          .label {
            color: $text-color;
          }
        }
        &.successful {
          .stepNumber {
            background: $success;
            color: $white;
            svg {
              path {
                fill: $white;
              }
            }
          }
        }
      }
      .separatorLine {
        display: none;
        border: 1px solid $gray-fifty;
        z-index: 1;

        &.show {
          display: flex;
          flex-grow: 1;
          transition: .5s;
        }
      }
    }

    @include s {
      padding: 1rem 1.5rem;
    }
    @include xs {
      padding: 0.625rem 1.25rem;
    }
  }
  .stepComponent {
    transition: .35s;
  }
  .button {
    margin: 0 4rem;
  }

  @include s {
    gap: 1.75rem;
  }
  @include xs {
    gap: 1.25rem;
  }
}
.errorMessage {
  div {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    vertical-align: center;
    margin: 0 auto;
    padding: .25rem .75rem;
    border-radius: 1rem;
    color: $neutrals3;
    background-color: $primary-color;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
.modalSuccessOuter {
  max-width: 21.125rem;
}
.parentDisable {
  position: fixed;
  top: 0;
  left: 0;
  background: $dark-light;
  opacity: 0.3;
  z-index: 1000;
  height: 100%;
  width: 100%;
}
