@import "../../../../styles/mainStyles.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  position: relative;
  text-align: center;
  transition: .6s;
  -webkit-animation: fadeIn .6s;
  animation: fadeIn .6s;

  max-width: 25rem;
  margin: 0 auto;

  @include xs {
    gap: 1rem;
  }

  .title {
    @include TitleFont;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 104%;
    letter-spacing: 0em;
    text-align: center;

    @include s {
      font-size: 1.75rem;
    }
    @include xs {
      font-size: 1.25rem;
    }
  }
  .price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: .5rem;

      .value {
        @include BoldFont;
        font-size: 3.75rem;
        font-weight: 600;
        line-height: 130%;
        letter-spacing: 0em;
      }
      .currency {
        @include RegularFont;
        font-size: 1.875rem;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: 0em;
        text-transform: uppercase;
        margin-top: .5rem;
      }
    }
    .officialCurrency {
      @include RegularFont;
      font-size: 1rem;
      font-weight: 400;
      line-height: 105%;
      letter-spacing: 0em;
    }
  }
  .properties {
    display: flex;
    flex-direction: column;
    gap: .75rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $neutrals5;

    .property {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      @include RegularFont;
      font-size: 1rem;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: 0em;

      span {
        @include BoldFont;
        font-weight: 600;
      }
    }
  }
  .paymentMethod {
    display: flex;
    flex-direction: column;
    gap: .75rem;

    .label {
      @include BoldFont;
      font-size: 1rem;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: 0em;
      text-align: start;
    }
    .method {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      @include RegularFont;
      font-size: 1rem;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0em;

      span {
        display: flex;
        gap: .25rem;
        align-items: center;

        .circle {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 100%;

          svg {
            width: .875rem;
            height: .875rem;
            transform: scale(1);
          }
        }
      }
      .changeMethod {
        display: flex;
        gap: .25rem;
        font-size: .875rem;
        align-items: center;
        color: $primary-color;

        &:hover {
          cursor: pointer;
          color: darken($primary-color, 2);
          text-decoration: underline;
        }
      }
    }
  }
  .button {
    margin-top: 1rem;
  }
}

.errorPaymentModal {
  span {
    width: 60%;

    @include xs {
      width: 70%;
    }
  }
}

.containerValidatingModal {
  padding-bottom: 2rem;
  > span {
    &:last-of-type {
      font-size: 1rem;
      width: 70%;
      line-height: 120%;
      @include s {
        font-size: .875rem;
        width: 85%;
      }
    }
  }
}

.loader {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(0deg, $bg-color 33%, $primary-color 100%);
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 50%;
  background: $bg-color;
}
@keyframes rotation {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg)}
}