@import '../../styles/mainStyles.scss';

.container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: .375rem;
}
.label {
  @include MediumFont;
  font-size: 1rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0em;
  text-align: left;

  &.error {
    color: $error;
  }
}
.box {
  display: flex;
  position: relative;
  box-sizing: border-box;

  &.error {
    .input {
      outline: 1px auto $error;
    }
  }

  input {
    width: 1rem;
    height: 1rem;
    border-radius: .5rem;

    &:hover {
      cursor: pointer;
      outline: 1px auto $primary-color;
    }
    &:disabled {
      cursor: not-allowed;
      &:hover {
        outline: none;
      }
    }
  }
}
.background {
  &-primary {
    input[type="checkbox"] {
      accent-color: $primary-color;
    }
  }
  &-secondary {
    input[type="checkbox"] {
      accent-color: $secondary-color;
    }
  }
  &-secondary-light {
    input[type="checkbox"] {
      accent-color: $blue-light;
    }
  }
  &-light {
    input[type="checkbox"] {
      accent-color: $light;
    }
  }
  &-white {
    input[type="checkbox"] {
      accent-color: $white;
    }
  }
  &-transparent {
    input[type="checkbox"] {
      accent-color: transparent;
    }
  }
}