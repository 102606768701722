@import "../../../../../../styles/mainStyles.scss";

.title {
  @include TitleFont;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 104%;
  letter-spacing: 0em;
  text-align: center;

  @include xs {
    font-size: 1.25rem;
  }
}
.price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .5rem;

    .value {
      @include BoldFont;
      font-size: 2.5rem;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0em;
    }
    .currency {
      @include RegularFont;
      font-size: 1.875rem;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0em;
      text-transform: uppercase;
      margin-top: .25rem;
    }
  }
  .officialCurrency {
    @include RegularFont;
    font-size: 1rem;
    font-weight: 400;
    line-height: 105%;
    letter-spacing: 0em;
  }
}
.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}
.paymentMeansContainer {
  display: flex;
  gap: 1rem;
  width: inherit;

  .paymentMean {
    display: flex;
    padding: 1px;
    height: 100%;
    width: 100%;
    background: $bg-color;
    border-radius: .625rem;

    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: .25rem;
      padding: 1.25rem 0;
      border-radius: .625rem;
      width: 100%;
      height: auto;

      background: $bg-color;
      z-index: 500;

      @include MediumFont;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 104%;

      > span {
        @include RegularFont;
        font-size: .75rem;
        white-space: nowrap;
      }

      @include s {
        font-size: .875rem;
        > span {
          font-size: .625rem;
        }
        padding: 1rem 0;
        height: fit-content;
      }
      @include xs {
        padding: .75rem 0;
      }
    }

    &.selected {
      background: $primary-gradient !important;
    }

    &.hoverEnabled {
      &:hover {
        cursor: pointer;
        background: linear-gradient(rgb(0 0 0/5%) 0 0);
      }
    }
  }
}
.properties {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .label {
    @include BoldFont;
    font-size: 1rem;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0em;
    text-align: start;
  }
  .propertyContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: .5rem 0;

    .whatsApp {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      > span {
        flex-grow: 1;
        @include RegularFont;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        text-align: left;
      }

      a {
        display: flex;
        align-items: center;
        height: 100%;
      }
      > svg {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .property {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: .75rem;
      @include RegularFont;
      font-size: 1.25rem;
      font-weight: 400;
      letter-spacing: 0em;
      font-style: normal;
      line-height: 100%;

      span {
        @include BoldFont;
        font-weight: 600;
      }

      @include s {
        font-size: 1rem;
      }
    }
    .line {
      width: stretch;
      border-bottom: 1px solid $gray-fifty;
    }
    .transferDetail {
      display: flex;
      flex-direction: column;
      gap: .25rem;

      > span {
        text-align: left;
        font-style: normal;
        font-weight: 600;
        line-height: 104%; /* 16.64px */
        padding-bottom: .25rem;
      }
    }
  }
}
.paymentMethod {
  display: flex;
  flex-direction: column;
  gap: .75rem;
  padding: 1rem 0;
  border-top: 1px solid $neutrals5;
  border-bottom: 1px solid $neutrals5;

  .label {
    @include BoldFont;
    font-size: 1rem;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0em;
    text-align: start;
  }
  .method {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include RegularFont;
    font-size: 1rem;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0em;

    span {
      display: flex;
      gap: .5rem;
      align-items: center;

      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;

        svg {
          width: .875rem;
          height: .875rem;
          transform: scale(1);
        }
      }
    }
    .changeMethod {
      display: flex;
      gap: .25rem;
      font-size: .875rem;
      align-items: center;
      color: $primary-color;

      &:hover {
        cursor: pointer;
        color: darken($primary-color, 2);
        text-decoration: underline;
      }
    }
  }
}
.footer {
  padding-top: 2rem;
  display: flex;
  @include RegularFont;
  font-size: .75rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}
.terms {
  display: inline-block;
  gap: .125rem;
  width: 100%;

  @include MediumFont;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.01rem;
  color: $text-color;

  .link {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.01rem;
    text-decoration-line: underline;
    color: $text-color;

    &:hover {
      cursor: pointer;
      color: $gray;
      @include dark {color: $light;}
    }
  }
}
.description {
  @include RegularFont;
  font-size: .875rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0em;
  text-align: center;
  width: 80%;

  @include xs {
    font-size: .75rem;
  }
}
.outerModal {
  // max-width: 17rem;
  padding: 1.25rem;
}
.containerModal {
  max-width: 22.5rem;
}
.uploader {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}