@import '../../styles/mainStyles.scss';

.progressBar {
  position: relative;
  display: flex;
  height: .625rem;
  width: 100%;
  border-radius: .313rem;
  background: $light-seventy-five;
  margin-bottom: 1rem;

  .completed {
    position: relative;
    display: flex;
    justify-content: flex-start;
    height: 100%;
    transition: width 1.5s ease-in-out;
    background: $primary-color;
    &.primary {
      background: $primary-color;
    }
    &.secondary {
      background: $secondary-color;
    }

    span {
      position: absolute;
      bottom: -1.625rem;
      right: -1.125rem;
      @include RegularFont;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 104%; /* 14.56px */
    }
  }
}