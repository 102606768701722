@import "../../../styles/mainStyles.scss";

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: relative;
  padding: 0 1.25rem;

  .prev {
    position: absolute;
    top: 0;
    left: .125rem;
    &:hover { cursor: pointer;}
  }
}
.title {
  @include TitleFont;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 104%;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 auto;
  width: 90%;

  @include m {
    width: 80%;
  }
  @include xs {
    width: 90%;
    font-size: 1.25rem;
  }
}
.subtitle {
  @include RegularFont;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 auto;
  width: 90%;

  @include xs {
    font-size: .875rem;
  }

  a {
    color: $text-color;
    &:hover{
      font-weight: 600;
    }
  }
}
.propertiesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-grow: 1;

  &.line {
    padding: 1.5rem 0;
    border-top: 1px solid $gray-fifty;
    border-bottom: 1px solid $gray-fifty;

    @include xs {
      padding: 1rem 0;
    }
  }

  .property {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;

    &.column {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: .5rem;
    }
    .copyRow {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      width: 100%;
      gap: .5rem;
      > span {
        @include RegularFont;
        font-weight: 400;
        line-height: 104%;
        letter-spacing: 0em;

        &:nth-of-type(2) {
          color: $success;
          font-weight: 600;
          line-height: 125%;
        }
        &.value {
          @include MediumFont;
          color: $text-color;
          font-weight: 700;
          margin-left: auto;
        }
      }
      svg {
        path {
          fill: $primary-color;
        }
        &:hover{ cursor: pointer;}
      }
    }
    > span {
      @include RegularFont;
      font-weight: 400;
      line-height: 104%;
      letter-spacing: 0em;

      &.address {
        @include MediumFont;
        font-weight: 600;
      }
    }
  }
}
.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}
.depositAmount {
  display: flex;
  flex-direction: column;
  padding-top: .5rem;
  .question {
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    line-height: 104%; /* 1.04rem */
  }
  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .5rem 0 1rem;
    gap: 1rem;

    .row {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: .5rem;
      @include RegularFont;
      font-size: 1.875rem;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0em;
      width: 100%;

      .inputContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        gap: .25rem;
        width: 100%;

        &.error {
          > span {
            color: $error;
            font-weight: 600;
          }
        }

        input {
          display: flex;
          @include MediumFont;
          // font-size: 3.75rem;
          font-weight: 600;
          letter-spacing: 0em;
          height: 4rem;
          min-width: 0;
          width: min-content;
          max-width: 100%;
          text-align: end;
          box-shadow: none;
          border: none;
          outline: none;
          background: none;
          position: relative;

          &:active,
          &:focus,
          &:focus-within,
          &:focus-visible,
          &:hover {
            border: none;
            box-shadow: none;
            outline: none;
          }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }
      }
    }
    .line {
      border: 1px solid $primary-color;
      width: 100%;
    }
    .info {
      margin: .5rem auto;
      width: stretch;
    }
    .currency {
      font-weight: bold;
      color: $primary-color;
    }
  }
}
.qr {
  display: flex;
  flex-direction: column;
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    aspect-ratio: 1 / 1;
    margin: 0 auto;
    border: 1px solid $gray-light;
    border-radius: .313rem;
    margin-bottom: 1.5rem;

    @include s {
      width: 80%;
    }

    svg {
      padding: 1.5rem;
      height: auto;
      max-width: 100%;
      width: 100%;
    }
  }
  > span {
    @include RegularFont;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 104%; /* 16.64px */
    > a {
      color: $primary-color;
    }
    > span {
      @include MediumFont;
      font-weight: 700;
    }
    @include s {
      font-size: .875rem;
    }
  }
}
.depositMethod {
  display: flex;
  flex-direction: column;
  gap: .75rem;
  padding: 1rem 0 0;
  border-top: 1px solid $neutrals5;

  .label {
    @include MediumFont;
    font-size: 1rem;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0em;
    text-align: start;
  }
  .method {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include RegularFont;
    font-size: 1rem;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0em;

    span {
      display: flex;
      gap: .5rem;
      align-items: center;

      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;

        img {
          width: 1.125rem;
          height: 1.125rem;
          transform: scale(1.5);
        }
      }
    }
    .changeMethod {
      display: flex;
      gap: .25rem;
      font-size: .875rem;
      align-items: center;
      color: $primary-color;

      &:hover {
        cursor: pointer;
        color: darken($primary-color, 2);
        text-decoration: underline;
      }
    }
  }
}
.walletIcon {
  background: $secondary-color;
  svg {
    width: 1rem;
    path {
      fill: setColor($secondary-color);
    }
  }
}
.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  .button {
    width: 100%;
    .copy {
      path {
        fill: setColor($primary-color);
      }
    }
  }
}
.highlighted {
  color: $primary-color;
  font-weight: 600;
}