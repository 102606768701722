@import './colors.scss';
@import './fonts.scss';
@import './functions.scss';
@import './breakpoints.scss';
@import './brand-blocks.scss';

$default-button-border-radius: $button-border-radius;

@mixin dark {
  @at-root :global(.dark) & {
    @content;
  }
}

@mixin flat-picker {
  .flatpickr-calendar {
    border-radius: .325rem;
    background: $base-color;
    color: setColor($base-color);
    @include dark {
      background: $gray;
      color: setColor($gray);
    }
    box-shadow: 0 .25rem 1.25rem 0 rgba(0, 0, 0, 0.25);
    @include RegularFont;
  }

  .flatpickr-months {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    background: $base-color;
    color: setColor($base-color);
    @include dark {
      background: $gray;
      color: setColor($gray);
    }
    padding: .5rem 1.25rem;
    border-bottom: 1px solid $dark-medium;
    border-top-right-radius: .325rem;
    border-top-left-radius: .325rem;
    > span {
      display: inherit;
      position: relative !important;
      justify-content: center;
      align-items: center;
      padding: 0 !important;

      svg {
        width: .625rem;
        height: 1rem;
        stroke: setColor($base-color);
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
      &:hover {
        svg {
          stroke: $primary-color;
        }
      }
    }

    .flatpickr-month {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      background: $base-color;
      color: setColor($base-color);
      @include dark {
        background: $gray;
        color: setColor($gray);
      }
      line-height: 104%;
      width: stretch;
      height: 1.75rem;

      .flatpickr-current-month {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: stretch;
        flex-grow: 1;
        left: 0;
        padding: 0 !important;

        > select {
          @include RegularFont;
          padding: 0;
          width: 55%;

          option {
            @include RegularFont;
            background: #E8E8E7;
            @include dark {
              background: $gray;
              color: setColor($gray);
            }
            &:hover, &:focus, &:focus-within, &:target {
              background: $primary-color !important;
              color: setColor($primary-color) !important;
            }
          }
        }
        > div {
          width: 35%;
          > span {
            border: none;
          }
        }
      }
    }
  }
  .flatpickr-innerContainer {
    background: $base-color;
    color: setColor($base-color);
    @include dark {
      background: $gray;
      color: setColor($gray);
    }
    border-bottom-right-radius: .325rem;
    border-bottom-left-radius: .325rem;
  }
  .flatpickr-weekdays {
    background: $base-color;
    @include dark {
      background: $gray;
      color: setColor($gray);
    }
    font-size: 1rem;
    padding: .5rem;
    width: auto;
    span {
      @include RegularFont;
      font-size: 1rem;
      font-weight: 400;
      background: $base-color;
      color: setColor($base-color);
      @include dark {
        background: $gray;
        color: setColor($gray);
      }
    }
  }
  .flatpickr-days {
    background: $base-color;
    color: setColor($base-color);
    @include dark {
      background: $gray;
      color: setColor($gray);
    }
    font-size: 1rem;
    border: none;

    .dayContainer {
      background: $base-color;
      color: setColor($base-color);
      @include dark {
        background: $gray;
        color: setColor($gray);
      }
      span {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: auto;
        aspect-ratio: 1 / 1;
        transition: background-color 0.3s ease-in-out; /* Add transition for smooth hover effect */
        font-size: 1rem; /* Font size */
        line-height: 104%; /* Line height */
        color: setColor($base-color);
        outline: none;
        margin: .125rem 0 !important;
        box-shadow: none !important;
        border: none !important;
        border-radius: 100% !important;

        &.today {
          -webkit-box-shadow: inset 3px 3px 4px $primary-color !important;
          -moz-box-shadow: inset 3px 3px 4px $primary-color !important;
          box-shadow: inset 0 0 0 1px $primary-color !important;
          background: $base-color;
          color: $primary-color;
          @include dark {
            background: $gray;
            color: setColor($gray);
          }
        }
        &.prevMonthDay, &.nextMonthDay {
          color: $gray-fifty;
          @include dark {color: $light-fifty;}
        }
        &.selected {
          background: $primary-color !important;
          color: setColor($primary-color) /* Range selected date text color */
        }
        &.startRange {
          background: $primary-color;
          &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: transparentize($primary-color, .7);
            z-index: -1;
            border-radius: 0 !important;
            border-bottom-left-radius: 100% !important;
            border-top-left-radius: 100% !important;
          }
        }
        &.endRange {
          background: $primary-color;
          &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: transparentize($primary-color, .7);
            z-index: -1;
            border-radius: 0 !important;
            border-bottom-right-radius: 100% !important;
            border-top-right-radius: 100% !important;
          }
        }
        &.inRange {
          background: transparentize($primary-color, .7) !important;
          border-radius: 0 !important;

          &:hover {
            background: transparentize($primary-color, .2) !important;
            color: setColor($primary-color) !important;
            border-radius: 100% !important;
          }

          &.today {
            border-radius: 100% !important;
            color: setColor($base-color);
            background: transparent !important;
            &::before {
              position: absolute;
              content: '';
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: transparentize($primary-color, .7) !important;
              z-index: -1;
              border-radius: 0 !important;
            }
          }
        }
        &:hover {
          background: transparentize($primary-color, .2);
          color: setColor($primary-color) !important;
        }
      }
    }
  }
}

html,
body {
  margin: 0;
  height: fit-content;
  min-height: 100vh;
  @include RegularFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include flat-picker;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}
code {
  @include RegularFont;
}

@mixin scrollBar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:horizontal {
    height: 0.313rem;
  }
  &::-webkit-scrollbar:vertical {
    width: 0.313rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $light-seventy-five;
    background: $light-seventy-five;
    border-radius: .5rem;
  }
  &::-webkit-scrollbar-track {
    border-radius: .625rem;
  }
  &::-webkit-scrollbar-track:horizontal {
    margin: 0 .25rem;
  }
  &::-webkit-scrollbar-track:vertical {
    margin: .25rem 0;
  }
}

@mixin title {
  @include RegularFont;
  @include TitleFont;
  font-style: normal;
  font-weight: 700;
  font-size: 2.75rem;
  line-height: 90%;
  line-height: 100%;
  letter-spacing: -0.02em;

  @include xl {
    font-size: 2.625rem;
  }
  @include l {
    font-size: 2.5rem;
  }
  @include m {
    font-size: 2.325rem;
  }
  @include s {
    font-size: 2.25rem;
    line-height: 120%;
  }
  @include xs {
    font-size: 2.125rem;
  }
}

@mixin bold-title {
  @include BoldFont;
  @include TitleFont;
  font-style: normal;
  font-weight: 700;
  font-size: 2.75rem;
  line-height: 100%;

  @include xl {
    font-size: 2.625rem;
  }
  @include l {
    font-size: 2.5rem;
  }
  @include m {
    font-size: 2.25rem;
  }
  @include s {
    font-size: 2rem;
  }
  @include xs {
    font-size: 1.375rem;
  }
}

@mixin description {
  @include RegularFont;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 120%;

  @include xl {
    font-size: 1.375rem;
  }
  @include l {
    font-size: 1.125rem;
  }
  @include m {
    font-size: 1rem;
  }
  @include s {
    font-size: .9rem;
  }
  @include xs {
    font-size: .875rem;
  }
}

.dark-background {
  background: $dark;
}
.black-background {
  background: $black;
}
.primary-background {
  background: $primary-color;
}
.secondary-background {
  background: $secondary-color;
}
.ternary-background {
  background: $ternary-color;
}
.gradient-background {
  background: $primary-gradient;
}
.dim {
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
  /* For Internet Explorer */
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, .3) !important;
  /* For other browsers */
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, .3) !important;
  pointer-events: none;
  z-index: 500;
}

.ripped {
  &::before {
    content: ' ';
    display: block;
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 3rem;
    background: url('../assets/images/top-ripped.png') no-repeat bottom;
    background-size: 100% 100%;
    z-index: 1;
  }
  &::after {
    content: ' ';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 3rem;
    background: url('../assets/images/bottom-ripped.png') no-repeat bottom;
    background-size: 100% 100%;
    z-index: 1;
  }
}

.bottom-ripped {
  &::after {
    content: ' ';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 3rem;
    background: url('../assets/images/bottom-ripped.png') no-repeat bottom;
    background-size: 100% 100%;
    z-index: 1;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@mixin Skeleton {
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;
  @include dark {background-color: $light-twenty-five;}

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    @include dark {
      background-image: linear-gradient(
        90deg,
        rgba(#777e91, 0) 0,
        rgba(#777e91, 0.2) 20%,
        rgba(#777e91, 0.5) 60%,
        rgba(#777e91, 0)
      );
    }
    animation: shimmer 2s infinite;
    content: '';
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

input {
  @include dark {
    color-scheme: dark;
  }
}