@import "../../../../styles/mainStyles.scss";

.warning {
  justify-content: flex-start;
  width: 100%;
}
.text {
  .check {
    display: none;
    @include m {
      display: block;
    }
    .link {
      @include MediumFont;
      text-decoration: underline;
      font-weight: 600;
      color: $primary-color;
      &:hover {cursor: pointer;}
    }
  }
}
.navigate {
  cursor: pointer;
  text-decoration: underline;
  color: $primary-color;
}