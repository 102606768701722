@import "../../styles/mainStyles.scss";

.section {
  padding: 1rem 0;
}
.container {
  width: stretch;
  flex-direction: column;
  gap: 1rem;
}

.header {
  display: flex;
  flex-direction: column;
  width: inherit;
  gap: 1rem;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      display: flex;
      flex-direction: column;
      gap: .5rem;

      > span {
        &:first-of-type {
          display:  flex;
          justify-content: center;
          align-items: center;
          gap: .5rem;
          @include MediumFont;
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 105%;
          letter-spacing: 0em;
          text-align: left;

          @include xs {
            font-size: 1.25rem;
          }

          svg {
            transform: rotate(90deg);
            @include xs {
              height: .75rem;
            }
          }
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
        &:last-of-type {
          font-size: .875rem;
          font-weight: 400;
          line-height: 104%;
          padding-left: 1.5rem;
        }
      }
    }
  }
}
.cardContainer {
  display: flex;
  width: inherit;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.625rem 1.75rem;
  height: auto;
  width: 100%;
  overflow-x: auto;
  @include m {width: stretch}
  @include scrollBar;
  &::-webkit-scrollbar-track:horizontal {
    margin: 0 .75rem;
  }
  @include s {
    padding: 1.25rem;
  }
  @include xs {
    padding: .75rem .5rem;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    min-width: 700px;
    tr {
      line-height: 100%;
      &:nth-of-type(2) {
        td {
          padding-top: .5rem;
        }
      }

      &.skeleton {
        td {
          &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6) {text-align: center;}
        }
      }

      span {
        @include RegularFont;
        font-size: .75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 104%;
      }

      th {
        line-height: 104%;
        border-bottom: 1px solid rgba(67, 67, 81, 0.20);
            @include dark {border-color: $light-fifty;}
        text-align: start;
        padding-bottom: .5rem;
      }
      td {
        padding: .25rem 0;
        text-align: start;

        &.contract {
          span {
            @include MediumFont;
            font-weight: 700;
            padding-right: 1rem;
            line-height: 104%;
            padding-right: 0;
          }
        }
        &.status {
          text-align: center;
          // padding-left: .75rem;
          width: 15%;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: .25rem .75rem;
            border-radius: .675rem;
            font-size: .675rem;
            width: 50%;
            white-space: nowrap;
            margin: 0 auto;

            @include m {
              width: 70%;
            }
            &.started {background: $yellow;}
            &.in_progress {background: $yellow;}
            &.signed {background: $success;}
            &.declined {background: $error;}
            &.expired {background: $yellow;}
          }
        }
        > a {
          font-size: .75rem;
          font-weight: 400;
          line-height: 104%;
          color: $primary-color;
          text-decoration: underline;
          &:hover {cursor:pointer}
        }
        .downloadLinkButton {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          color: unset;
          svg {
            vertical-align: middle;
            &:hover {
              cursor: pointer;
            }
            path {
              stroke: $gray;
              stroke-width: 1.2;
              @include dark {stroke: $light;}
            }
          }
          .download {
            &:hover {
              cursor: pointer
            }
          }
          .loading {
            max-height: 24px;
          }
        }
      }
      th, td {
        @include s {
          text-align: center;
        }
        &.sign, &.status, &.download {
          text-align: center;
        }
        &:last-of-type {
          width: 0;
        }
      }

      &.emptyState {
        section {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: auto;
          gap: .75rem;
          flex-grow: 1;
          padding: 2rem 0;

          span {
            @include MediumFont;
            font-weight: 500;
            font-size: .875rem;
            line-height: 104%;
            color: $gray-fifty;
            @include dark {color: $light-fifty;}
          }
        }
      }
    }
  }

  .emptyText {
    @include MediumFont;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .875rem;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0em;
    color: $gray-fifty;
    @include dark {color: $light-fifty;}
    text-align: center;
    max-width: 10rem;
    flex-grow: 1;
    margin: 1.5rem auto 1rem;
  }
}