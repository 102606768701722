
@import "../../styles/mainStyles.scss";

.section {
  padding: 2rem 0;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
  margin: 0 auto;
  transition: .35s;
  max-width: 25rem;

  @include xs {
    gap: 1rem;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    position: relative;
    padding: 0 1.25rem;

    > span {
      &:first-of-type{
        @include TitleFont;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 104%;
        letter-spacing: 0em;
        text-align: center;

        @include xs {
          font-size: 1.25rem;
        }
      }
      &:nth-of-type(2) {
        @include RegularFont;
        font-weight: 400;
        line-height: 104%;
        letter-spacing: 0em;
        text-align: center;

        @include xs {
          font-size: .875rem;
        }
      }
    }

    .prev {
      position: absolute;
      top: 0;
      left: .125rem;
      &:hover { cursor: pointer;}
    }
  }
}
.withdrawalMethodContainer {
  display: flex;
  flex-direction: column;
  gap: .5rem;

  .withdrawalMethod {
    padding: 1px;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    background: $gray-fifty;
    border-radius: .675rem;

    .box {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1.25rem;
      border-radius: .625rem;
      width: auto;
      height: auto;

      background: $bg-color;
      z-index: 500;

      @include xs {
        padding: 1rem;
        height: fit-content;
      }

      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 100%;

        @include s {
          width: 3rem;
          height: 3rem;
        }
        @include xs {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
      .textContainer {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        background: $bg-color;
        @include s {
          gap: .25rem;
        }
        .name {
          @include MediumFont;
          font-size: 1.25rem;
          font-weight: 500;
          line-height: 100%;
          letter-spacing: 0em;
          text-align: left;

          @include xs {
            font-size: 1rem;
          }
        }
        .description {
          display: flex;
          flex-direction: row;
          gap: .5rem;
          @include RegularFont;
          font-size: 1rem;
          font-weight: 500;
          line-height: 100%;
          letter-spacing: 0em;
          text-align: left;
          color: $gray-fifty;
          @include dark {color: $light-fifty;}

          @include xs {
            font-size: .875rem;
          }
        }
      }
      .right {
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .walletIcons {
          margin-left: auto;
          display: grid;
          grid: 'a a a';
          gap: .5rem;
          @include s {
            gap: .25rem;
          }
        }
        .arrow {
          margin-left: auto;
          transform: rotate(90deg);

          line {
            fill: $gray-fifty;
          }
        }
      }
    }

    &.disable, &.comingSoon {
      cursor: not-allowed !important;
      background: $gray-fifty;
      .box {
        color: $gray-fifty !important;
        .circle {
          background: $gray-fifty;
        }
      }
    }

    &:hover {
      cursor: pointer;
      background: linear-gradient(rgb(0 0 0/5%) 0 0);

      .box {
        background-image: linear-gradient(rgb(0 0 0/5%) 0 0);
        .textContainer {
          background-image: linear-gradient(rgb(0 0 0/5%) 0 0);
        }
      }
      @include dark {
        background: $light;
        .box {
          background-image: $light;
          .textContainer {
            background-image: $light;
          }
        }
      }
    }
  }
  .pesos, .usdt {
    .description {
      &.disable {
        color: $gray-fifty !important;
      }
    }
  }
}
.pesosIcon, .usdtIcon {
  min-width: 3.125rem;
  min-height: 3.125rem;
  @include s {
    min-width: 3rem;
    min-height: 3rem;
  }
}
.outerModal {
  padding: 1.5rem;
}
.containerModal {
  max-width: 19rem;
}