@import "../../styles/mainStyles.scss";

.section {
  padding: 2rem 0;
}
.container {
  display: flex;
  flex-direction: column;
  gap: .75rem;
  width: 100%;
  margin: 0 auto;
  transition: .35s;
  max-width: 25rem;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 1.25rem;

    > span {
      @include TitleFont;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 104%;
      letter-spacing: 0em;
      text-align: center;

      @include xs {
        font-size: 1.25rem;
      }
    }

    .prev {
      position: absolute;
      top: 0;
      left: .125rem;
      &:hover { cursor: pointer;}
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    width: stretch;
    gap: 1rem;
  }
}
.modal {
  max-width: 22rem;
}
.pending {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;

  > span {
    @include RegularFont;
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 104%;
    text-transform: uppercase;
    color: $yellow;
  }
}
.description {
  @include RegularFont;
  font-size: .875rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0em;
  text-align: center;
  width: 80%;

  @include xs {
    font-size: .75rem;
  }
}