@import '../../styles/mainStyles.scss';

.pager {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: .5rem;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: max-content;
      &.rotate {
        transform: rotate(180deg);
      }
    }
    @include dark {
      path {fill: $text-color;}
    }
    &:disabled {
      path {fill: $light-twenty-five;}
    }
  }

  .dots {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 .5rem;
    max-width: 1.5rem;
    max-height: 1.5rem;
    font-weight: 700 !important;
    font-size: 1rem !important;
    color: $text-color;
  }
}