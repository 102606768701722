@import "../../../../../styles/mainStyles.scss";

.section {
  padding: 1rem 0;
}

.container {
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  transition: 0.35s;
  max-width: 25rem;
  gap: 2rem;
}
.stepperContainer {
  gap: 1.5rem;
}
.stepsContainer {
  padding: .75rem 1.5rem !important;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  width: 100%;
  position: relative;

  .prev {
    &:hover {
      cursor: pointer;
    }

    @include s {
      left: 1rem;
    }
    @include xs {
      left: 0;
    }
  }

  span {
    @include TitleFont;
    font-weight: 600;
    font-size: 1.5rem;
    width: 60%;
    @include s {
      font-size: 1.25rem;
      width: 80%;
    }

    &.last {
      width: 80%;
    }
  }
}