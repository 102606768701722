@import "../../../../styles/mainStyles.scss";

.section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex-grow: 1;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-top: .5rem;
      .title {
        @include TitleFont;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 104%;
      }
      .subtitle {
        @include RegularFont;
        font-size: .75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 104%;
      }
    }
  }

  .body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.25rem;
    width: stretch;
    @include m {flex-direction: column}

    .card {
      height: auto;
      flex-direction: column;
      width: 35%;
      padding: 1rem;

      @include m {width: stretch}

      &.tableCard {
        width: 65%;
        align-items: flex-start;
        overflow-x: auto;
        @include m {width: stretch}

        @include scrollBar;
        &::-webkit-scrollbar-track:horizontal {
          margin: 0 .75rem;
        }
      }

      table {
        width: 100%;
        border-collapse: collapse;
        min-width: 450px;

        tr {
          line-height: 100%;
          &:nth-of-type(2) {
            td {
              padding-top: .5rem;
            }
          }
          span {
            @include RegularFont;
            font-size: .75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 104%;
          }

          th {
            line-height: 104%;
            border-bottom: 1px solid rgba(67, 67, 81, 0.20);
            @include dark {border-color: $light-fifty;}
            text-align: start;
            padding-bottom: .5rem;

            &.check {
              width: 0%;
              input {
                margin-right: .5rem;
                vertical-align: middle;
              }
            }
            &.project, &.dueDate, &.amountOwed {
              width: 27.5%;
            }
            &:last-of-type {
              width: 17.5%;
              text-align: end;
            }
          }
          td {
            padding: .25rem 0;
            text-align: start;

            &.check {
              width: 0%;
              input {
                margin-right: .5rem;
                vertical-align: middle;
              }
            }
            &.project {
              span {
                @include MediumFont;
                font-weight: 700;
                text-transform: uppercase;
              }
            }
            &.actions {
              text-align: end;
              span {
                @include RegularFont;
                color: $primary-color;
                text-align: center;
                font-size: .75rem;
                font-style: normal;
                font-weight: 400;
                line-height: 104%; /* 10.4px */
                text-decoration-line: underline;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
          th, td {
            @include s {
              text-align: center;
            }
          }
          &.itemRow {
            td {
              &:last-of-type {
                padding-right: .25rem;
              }
            }
            &:hover {
              cursor: pointer;
              td {
                background: $gray-light;
                @include dark {background: $gray;}
                &:first-of-type {
                  border-top-left-radius: .313rem;
                  border-bottom-left-radius: .313rem;
                }
                &:last-of-type {
                  border-top-right-radius: .313rem;
                  border-bottom-right-radius: .313rem;
                }
              }
            }
          }
          &.emptyState {
            section {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: auto;
              gap: .75rem;
              flex-grow: 1;
              padding: 2rem 0;

              span {
                @include MediumFont;
                font-weight: 500;
                font-size: .875rem;
                line-height: 104%;
                color: $gray-fifty;
                @include dark {color: $light-fifty;}
              }
            }
          }
        }
      }
    }
  }
}

input[type=checkbox]{
  accent-color: $primary-color;
  color: #fff;
}