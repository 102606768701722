@import "../../../../styles/mainStyles.scss";

.section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex-grow: 1;
  @include m {gap: 1rem;}

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-top: .5rem;
      .title {
        @include TitleFont;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 104%;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.25rem;
    width: stretch;

    .metrics {
      display: inline-flex;
      gap: 1rem;
      width: 100%;

      .col {
        display: grid;
        gap: 1rem;
        flex-wrap: wrap;
        width: fit-content;
      }
      .grid {
        display: grid;
        grid: 'a a';
        gap: 1rem;
        grid-auto-rows: 1fr;
        flex-wrap: wrap;
        width: fit-content;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        @include m { width: 100%;}
      }
      .grid, .col {
        > div {
          max-width: 13.75rem;
          box-sizing: content-box;
        }
        span {
          &:first-of-type {
            @include RegularFont;
            font-size: .75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 104%; /* 12.48px */
            width: 70%;
          }
          &:last-of-type {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            gap: .5rem;

            @include MediumFont;
            font-size: 1.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 104%; /* 12.48px */
            color: $primary-color;

            @include m {font-size: 1.75rem;}

            &.currency {
              @include RegularFont;
              font-size: .938rem;
              line-height: 135%; /* 12.48px */
            }
          }
        }
      }
      .percentageMetric {
        box-sizing: border-box;
        max-width: 8.875rem;
        height: 100%;
        flex-grow: 1;
        > span {
          width: 90%;
        }
      }

      @include s {
        display: flex;
      }
      .card {
        flex-direction: column;
        align-items: flex-start;
        gap: .5rem;
        height: fit-content;
        width: auto;
        padding: 1rem;
        @include s {
          width: stretch;
        }

        span {
          &:first-of-type {
            @include RegularFont;
            font-size: .75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 104%; /* 12.48px */
          }
          &:last-of-type {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            gap: .5rem;

            @include MediumFont;
            font-size: 1.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 104%; /* 12.48px */
            color: $primary-color;

            @include m {font-size: 1.75rem;}

            &.currency {
              @include RegularFont;
              font-size: .938rem;
              line-height: 135%; /* 12.48px */
            }
          }
        }
      }
    }

    .tableCard {
      height: auto;
      flex-direction: column;
      align-items: flex-start;
      width: stretch;
      padding: 1rem;
      gap: .5rem;
      align-items: flex-start;
      overflow-x: auto;

      @include scrollBar;
      &::-webkit-scrollbar-track:horizontal {
        margin: 0 .75rem;
      }

      > span {
        @include MediumFont;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 104%; /* 16.64px */
      }
      table {
        width: 100%;
        border-collapse: collapse;
        min-width: 1000px;

        tr {
          line-height: 100%;
          &:nth-of-type(2) {
            td {
              padding-top: .5rem;
            }
          }

          span {
            @include RegularFont;
            font-size: .75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 104%;
          }

          th {
            line-height: 104%;
            border-bottom: 1px solid rgba(67, 67, 81, 0.20);
            @include dark {border-color: $light-fifty;}
            text-align: start;
            padding-bottom: .5rem;

            &.projectStatus {
              width: 10%;
              span {
                padding-left: .75rem;
              }
            }
            &.tokenPrice {
              span {
                padding-right: 1rem;
              }
            }
          }
          td {
            padding: .25rem 0;
            text-align: start;

            &.project {
              span {
                @include MediumFont;
                font-weight: 700;
                padding-right: 1rem;
                line-height: 104%;
                padding-right: 0;
              }
            }
            &.status {
              padding-left: .75rem;
              span {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: .25rem .75rem;
                border-radius: .675rem;
                font-size: .675rem;
                width: 60%;
                white-space: nowrap;

                @include m {
                  width: 70%;
                }

                &.underReview {
                  background: $yellow;
                }
                &.seekingFunding {
                  border: 1px solid $blue;
                }
                &.funded {
                  color: $bg-color;
                  background: $purple;
                }
                &.sold {
                  background: $success;
                }
              }
            }
          }
          th, td {
            @include s {
              text-align: center;
            }
          }

          &.emptyState {
            section {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: auto;
              gap: .75rem;
              flex-grow: 1;
              padding: 2rem 0;

              span {
                @include MediumFont;
                font-weight: 500;
                font-size: .875rem;
                line-height: 104%;
                color: $gray-fifty;
                @include dark {color: $light-fifty;}
              }
            }
          }
        }
      }
    }

  }
}