@import '../../styles/mainStyles.scss';

.section {
  background: $footer-bg-color;
}

.container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .75rem;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 1rem;

  @include s {
    padding: 2rem 2rem 1rem;
  }
}

.slogan {
  @include RegularFont;
  color: $light-fifty;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 1.25rem;

  @include s {
    flex-direction: column;
    padding: 0 0;
  }
}
.column {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: flex-start;

  @include s {
    width: fit-content;
  }
}
.columnToLeft {
  text-align: left;
  margin-left: auto;
  gap: 1rem;
}
.categoryColumn {
  @include m {
    display: none;
  }
}
.lastColumn {
  justify-content: space-between;
  align-items: flex-end;

  @include s {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

.category {
  @include RegularFont;
  color: $light-fifty;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  padding-bottom: 2rem;
}

.copyrightRow {
  justify-content: flex-start;
  gap: .25rem;

  @include m {
    flex-direction: column;
    align-items: flex-start;
  }

  .links {
    display: flex;
    gap: .25rem;

    .link {
      @include RegularFont;
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      text-decoration: underline;
      text-decoration-line: underline;
      color: $light;
    }
  }
}

.line {
  width: 100%;
  border-top: 1px solid $light-twenty-five;
}

.copyright {
  @include RegularFont;
  font-style: normal;
  font-weight: 400;
  font-size: .75rem;
  line-height: 1.25rem;
  color: $light;
  font-feature-settings: "liga" off;
  display: flex;
  align-items: center;
  justify-content: center;
}

.legal {
  @include InterRegular;
  color: $light-fifty;
  text-align: justify;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.columnHeader {
  @include RegularFont;
  font-weight: 700;
  color: $light-fifty;
}
.columnItem {
  @include RegularFont;
  font-size: .875rem;
  font-weight: 600;
  color: $light-twenty-five;
}

.firstRow {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .dropdownMobileWrapper {
    display: none;
    @include s {
      display: flex;
      .languageDropdownHead {
        height: 2.25rem;
        min-width: 3rem;
        padding: 0 1.5rem 0 1rem;
      }

      .languageDropdownArrow {
        right: .25rem;
      }
    }
    @include xs {
      .languageDropdownHead {
        height: 2.175rem;
        min-width: 2rem;
        padding: 0 1.25rem 0 .75rem;
      }

      .languageDropdownArrow {
        right: 0;
      }
    }
  }

}

.dropdownWrapperDesktop {
  @include s {
    display: none;
  }

  .languageDropdownHead {
    height: 2.5rem;
    min-width: 10rem;
  }

  .languageDropdownArrow {
    right: .5rem;
  }
}

.socialsWrapper {
  justify-content: flex-end;
  align-content: flex-end;
}

.socials {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: flex-end;
  width: 100%;
}

.social {
  img {
    width: 1.375rem;
    height: 1.375rem;
  }

  &:not(:last-child) {
    margin-right: 1rem;

    @include s {
      margin-right: .75rem;
    }
  }
}

.logo {
  @include s {
    max-width: 60%;
  }
  @include xs {
    max-width: 50%;
  }
}

.poweredBy {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;

  span {
    @include RegularFont;
    font-size: .75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    text-transform: uppercase;
    color: $light;

    @include s {
      font-size: .625rem;
    }
  }
}