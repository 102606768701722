@import "../../../../../../styles/mainStyles.scss";

.title {
  @include TitleFont;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 104%;
  letter-spacing: 0em;
  text-align: center;

  @include xs {
    font-size: 1.25rem;
  }
}
.price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .5rem;

    .value {
      @include BoldFont;
      font-size: 2.5rem;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0em;
    }
    .currency {
      @include RegularFont;
      font-size: 1.875rem;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0em;
      text-transform: uppercase;
      margin-top: .25rem;
    }
  }
  .officialCurrency {
    @include RegularFont;
    font-size: 1rem;
    font-weight: 400;
    line-height: 105%;
    letter-spacing: 0em;
  }
}
.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}
.qr {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    aspect-ratio: 1 / 1;
    margin: 0 auto;
    border: 1px solid $gray-light;
    border-radius: .313rem;

    @include s {
      width: 80%;
    }

    svg {
      padding: 1.5rem;
      height: auto;
      max-width: 100%;
      width: 100%;
    }
  }
  > span {
    @include RegularFont;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 104%; /* 16.64px */
    > a {
      color: $primary-color;
    }

    @include s {
      font-size: .875rem;
    }
  }
}
.paymentMethod {
  display: flex;
  flex-direction: column;
  gap: .75rem;
  padding: 1rem 0 0;
  border-top: 1px solid $neutrals5;

  .label {
    @include BoldFont;
    font-size: 1rem;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0em;
    text-align: start;
  }
  .method {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include RegularFont;
    font-size: 1rem;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0em;

    span {
      display: flex;
      gap: .5rem;
      align-items: center;

      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;

        img {
          width: 1.125rem;
          height: 1.125rem;
          transform: scale(1.5);
        }
      }
    }
    .changeMethod {
      display: flex;
      gap: .25rem;
      font-size: .875rem;
      align-items: center;
      color: $primary-color;

      &:hover {
        cursor: pointer;
        color: darken($primary-color, 2);
        text-decoration: underline;
      }
    }
  }
}
.description {
  @include RegularFont;
  font-size: .875rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0em;
  text-align: center;
  width: 80%;

  @include xs {
    font-size: .75rem;
  }
}
.outerModal {
  padding: 1.5rem;
}
.containerModal {
  max-width: 19rem;
}
.pending {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;

  > svg {
    path {
      stroke: $primary-color;
    }
  }

  > span {
    @include RegularFont;
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 104%;
    text-transform: uppercase;
    color: $primary-color;
  }
}