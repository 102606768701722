@import "../../../styles/mainStyles.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  width: stretch;
  margin: 0 auto;
  max-width: 30rem;
  padding: 2.5rem 2rem;

  @include s {
    padding: 2rem 1.5rem;
    gap: 1rem;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: .75rem;
    width: 100%;
    position: relative;

    .prev {
      position: absolute;
      top: 10%;
      left: 0;
      &:hover {
        cursor: pointer;
      }

      @include s {
        left: 1rem;
      }
      @include xs {
        left: 0;
      }
    }

    span {
      width: 60%;
      margin: 0 auto;
      @include s {
        width: 80%;
      }

      &.last {
        width: 80%;
      }
    }
    .title {
      @include TitleFont;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: 0em;
      text-align: center;
    }

    @include s {
      .title { font-size: 1.25rem; }
    }
  }

  .clarification {
    font-size: 1rem;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0em;
    text-align: center;
    @include s {font-size: .875rem; }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 1.125rem;
    width: 100%;

    > input {
      width: auto;
    }

    .row {
      display: flex;
      flex-direction: row;
      width: stretch;
      max-width: 100%;
      gap: 1rem;

      > div {
        &.city {
          @include xs {
            width: calc(60% - .5rem);
          }
        }
        &:last-of-type {
          width: 30%;
          @include xs {
            width: calc(40% - .5rem);
          }
        }
        &.street {
          width: 51.111%;
        }
        &.number, &.floor, &.department {
          width: 13.333%;
        }
        @include xs {
          &.street { width: 100%;}
          &.number, &.floor, &.department { width: calc(33% - .625rem);}
        }
      }

      &.special, &.specialReverse {
        @include xs {
          flex-wrap: wrap;
        }
      }
      &.specialReverse {
        > div {
          &:last-of-type {
            width: 74%;
            @include xs {
              width: calc(64% - .5rem);
            }
          }
        }
      }
    }

    .title {
      @include TitleFont;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.5rem;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .checkboxContainer {
    display: flex;
    align-items: flex-start;
    // justify-content: space-between;
    gap: .75rem;
    width: 100%;
    padding: 0 .5rem;
    .checkbox {
      width: min-content;
    }
    .textWrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: .25rem;
      padding-top: .25rem;

      .label {
        @include MediumFont;
        font-size: .875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 104%; /* 14.56px */
        &.bold { font-weight: 700;}
      }
      .description {
        @include RegularFont;
        font-size: .75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 104%; /* 12.48px */
      }
      a {
        @include RegularFont;
        color: $primary-color;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 104%; /* 12.48px */
        text-decoration: none;
      }
    }

  }

  .button {
    width: 100%;
    margin: 2rem auto 0;
    @include s {
      margin: 1rem auto 0;
    }
  }
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  .copied {
    @include MediumFont;
    font-size: .75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 104%; /* 12.48px */
    color: $text-color;

    display: flex;
    visibility: hidden;
    justify-content: center;
    align-items: center;
    padding: .25rem 0;
    width: 100%;
    background: rgba(216, 5, 135, 0.50);

    &.show {
      visibility: visible;
      -webkit-animation: fadeIn .7s ease-in-out;
      animation: fadeIn .7s ease-in-out;
    }
  }
  .brandLogo {
    padding-bottom: 1rem;
  }

  .title {
    @include TitleFont;
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 104%; /* 24.96px */
  }
  .wallet {
    @include RegularFont;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .address {
    display: flex;
    align-items: center;
    gap: .25rem;
    width: fit-content;
    margin: 0 auto;

    &:hover {
      cursor: pointer;
    }

    @include MediumFont;
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 104%; /* 20.8px */
    text-transform: uppercase;

    svg {
      path {
        fill: $primary-color;
      }
    }
  }
}
.dropdown {
  width: 100%;
  .head {
    height: 2.5rem;
  }
  .arrow {
    svg {
      right: .125rem;
      path {
        stroke: $text-color;
      }
    }
  }

}
.successOuterModal {
  padding: 1rem 1rem 2rem;
}
.containerModal {
  max-width: 22.5rem;
  gap: 1rem;
}
.containerValidatingModal {
  padding-bottom: 2rem;
  > span {
    &:last-of-type {
      font-size: 1rem;
      width: 70%;
      line-height: 120%;
      @include s {
        font-size: .875rem;
        width: 85%;
      }
    }
  }
}

.loader {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(0deg, $bg-color 33%, $primary-color 100%);
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 50%;
  background: $bg-color;
}
@keyframes rotation {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg)}
}