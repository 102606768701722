@import "../../../../../../styles/mainStyles.scss";

.container {
  padding: 2.5rem 0 1.5rem;
  gap: 1rem;
}

.title {
  @include TitleFont;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2.5rem;
  letter-spacing: 0em;
  text-align: center;
}

.stripe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  gap: 10px;

  color: $light;
  font-size: 2rem;
}