@import "../../styles/mainStyles.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  align-items: flex-start;
  flex-grow: 1;
  max-width: 100%;

  > div {
    width: stretch;
  }

  &.underlined {
    gap: 0;
    @include dark {
      gap: .625rem;
    }
  }
}
.label {
  @include MediumFont;
  font-size: 1rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0em;
  text-align: left;

  &.error {
    color: $error;
  }
}
.dropdown {
  position: relative;
  z-index: 3;
  width: 100%;

  &.active,
  &.focus-within {
    z-index: 10;

    .head {
      .arrow {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .body,
    .bodyContainer {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
}
.head {
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1;
  gap: .5rem;

  padding: 0 2rem 0 1rem;

  height: 48px;
  width: auto;
  font-size: 1.125rem;

  cursor: pointer;

  border-radius: .5rem;
  box-shadow: inset 0 0 0 1px $gray-fifty;

  &.error{
    box-shadow: inset 0 0 0 1px $error;
  }

  transition: box-shadow $animation-timing-default;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);


  &:hover,
  &:active,
  &:focus-within,
  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 1px $gray;
  }

  &.underlined {
    border: none;
    box-shadow: inset 0 -1px 0 0 $gray-fifty;
    border-radius: 0;
    height: 2.5rem;
    padding: 0;

    &:active,
    &:focus,
    &:focus-within,
    &:focus-visible,
    &:hover {
      outline: none;
      box-shadow: inset 0 -1px 0 0 $gray-fifty;
    }

    &.error {
      box-shadow: none;
      box-shadow: inset 0 -1px 0 0 $error;
    }

    @include dark {
      box-shadow: none;
      background: $dark-light;
      color: setColor($dark-light);
      border-radius: .625rem;
      padding: 0 .5rem;
    }

    .arrow {
      svg {
        right: .25rem;
      }
    }
  }
}

.avatar {
  margin-right: .375rem;

  width: 1.5rem;
  height: 1.5rem;

  img {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.selection {
  overflow: hidden;
  color: $text-color;

  font-size: 1.125rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
}

.arrow {
  position: absolute;
  top: 50%;
  right: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  // width: 32px;
  // height: 32px;

  border-radius: 50%;

  transform: translateY(-50%);

  svg {
    transition: transform $animation-timing-default;

    path {
      stroke: $primary-color;
    }
  }

}

.bodyContainer {
  position: absolute;
  top: calc(100% + 2px);
  right: 0;
  left: 0;

  background: $bg-color;
  @include dark { background: #2F3035}
  border-radius: .625rem;
  opacity: 0;
  box-shadow: 0 .25rem 1.25rem $shadow-color;

  visibility: hidden;

  transition: all $animation-timing-default;
  transform: translateY(3px);

  &.underlined {
    top: calc(100%);
  }
}

.body {
  max-height: 12rem;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  margin-right: 1.5px;

  @include scrollBar;
}

.option {
  display: flex;
  align-items: center;
  padding: .25rem .5rem;

  @include RegularFont;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.25rem;
  color: $text-color;

  cursor: pointer;

  transition: color $animation-timing-default;
  -webkit-tap-highlight-color: $light-fifty;

  &:hover,
  &.selected {
    > span {
      color: setColor($primary-color);
    }
    background: $primary-color;
  }

  &:first-of-type {
    margin-top: .75rem;
  }
  &:last-of-type {
    margin-bottom: .75rem;
  }
}

.icon {
  display: flex;
}

.text {
  @include RegularFont;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.25rem;
  color: $text-color;
  margin-left: .5rem;
}

.selectioned {
  font-weight: bold;
  color: $text-color;
}
