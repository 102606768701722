@import '../../../../styles/mainStyles.scss';

.row {
  display: flex;
  gap: 1.25rem;

  .button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: .5rem 0;
    gap: .5rem;
    @include MediumFont;
    font-size: .875rem;
    font-weight: 600;
    letter-spacing: 0em;
    border-bottom: 3px solid transparent;

    &.active {
      &::before {
        content : "";
        position: absolute;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        bottom: 0;
        height: 1px;
        width: 3rem;  /* or 100px */
        border-bottom: 2px solid $primary-color;
      }
    }

    &:hover {
      cursor: pointer;
      opacity: .8;
    }
    @include m {
      font-size: .75rem;
      gap: .375rem;
    }
  }
  @include m {
    gap: 1rem;
  }
}