@import "../../../../styles/mainStyles.scss";

.myTokens {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  width: auto;
  padding: 1.5rem;
  position: relative;
  overflow: visible;
  @include dark {background: $dark-light;}

  @include s {
    padding: 1.25rem;
  }
  @include xs {
    padding: 1rem;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    > span {
      @include MediumFont;
      font-weight: 500;
      line-height: 105%;
      letter-spacing: 0em;
      text-align: left;
      @include xs {
        font-size: .875rem;
      }
    }
    @include s {
      margin-bottom: .75rem;
    }
  }

  .gridHeader, .gridRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .headerColumn, .rowColumn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 17%;

      &:first-of-type {
        width: 34%;
      }
      &:last-of-type {
        width: 15%;
      }
    }
  }
  .gridHeader {
    padding-bottom: .5rem;
    border-bottom: 1px solid $neutrals5;
    .headerColumn {
      @include RegularFont;
      font-size: .75rem;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0em;
    }
  }

  .row {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: .25rem 0;
    border-bottom: 1px solid $neutrals5;
    @include s {
      border-bottom: 0px solid $neutrals5;
      border-top: 1px solid $neutrals5;
    }
    .tokenLink{
      &:hover {
        cursor: pointer;
        opacity: .9;
      }

      svg {
        margin: .06rem;
        width: 1rem;
        height: 1rem;
        fill: $ternary-color;
        path {
          fill: $ternary-color;
        }
      }
    }
    .gridRow {
      .returnProperty {
        font-size: .75rem;
      }
      .circle {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          cursor: pointer;
          opacity: .9;
        }

        svg {
          width: 1.25rem;
          height: 1.25rem;
          path {
            fill: $primary-color;
          }
        }
      }
      .columnRow {
        display: flex;
        align-items: center;
        gap: .75rem;
        width: 17%;
        &:first-of-type { width: 34%;}
        &:last-of-type { width: 15%;}
        @include s {
          width: 100% !important;
        }

        .image {
          width: 4.675rem;
          height: 4.725rem;
          border-radius: .625rem;
        }
        .text {
          display: flex;
          flex-direction: column;
          gap: .25rem;

          .name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: .5rem;
            @include MediumFont;
            font-size: 1.25rem;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: 0em;
            text-align: left;

            svg {
              min-width: 1.25rem;
            }
          }
          .location {
            @include MediumFont;
            font-size: .875rem;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: 0em;
            text-align: left;
            color: $primary-color;
          }

          &:hover {
            cursor: pointer;
            opacity: .8;
          }
        }
      }
      &:not(:first-child) {
        opacity: .7;
      }
      .expand{
        cursor: pointer;
        scale: 1.2;
        display: none;
        @include s{
          display: block;
        }
        transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
      .expanded{
        rotate: 180deg;
      }
    }
    .investmentDetailContainer{
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
      transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    .hidden{
      max-height: 0;
    }
    .expanded{
      max-height: 220px;
    }
    .investmentDetailRow{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: .5rem 0;
      border-top: 1px solid $neutrals6;
    }
    .investmentDetailCol{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: .5rem;
    }
    .clarification {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      font-size: .625rem;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0em;
      color: $gray-fifty;
      @include dark {color: $light-fifty;}
    }
  }
  .seeMore {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: .25rem;
    text-transform: lowercase;
    font-size: .875rem;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0em;
    width: 100%;
    padding-top: .5rem;
    color: $ternary-color;

    &:hover {
      cursor: pointer;
    }

    svg {
      width: .5rem;
    }
  }
}

.emptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  gap: .75rem;
  flex-grow: 1;
  padding: 2rem 0;

  span {
    @include MediumFont;
    font-weight: 500;
    font-size: .875rem;
    line-height: 104%;
    color: $gray-fifty;
    @include dark {color: $light-fifty;}
  }
}

.onBoarding {
  left: 35%;
  @include s {
    top: -4.5rem;
    left: unset;
    right: 5%;
  }
}