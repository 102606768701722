@import "../styles/mainStyles.scss";

// NavigatorButtons - svg icons color
// path - fill: drone, 360, RA, metaverse, gallery
.path-fill {
  path {
    fill: $secondary-color;
  }
}
// path - stroke: maps
.path-stroke {
  path {
    stroke: $secondary-color;
  }
}