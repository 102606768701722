@import '../../styles/mainStyles.scss';


.card {
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: $bg-color;
  color: $text-color;

  &.second {
    border-radius: .313rem;
  }
  &.straightCorners {
    border-radius: .313rem;

    .status, .button, .soldTokens .label {
      border-radius: .313rem;
    }
  }
}

.row {
  flex-direction: row;
  justify-content: space-between;
  @include s {
    flex-direction: column-reverse;
  }

  &.second {
    flex-direction: row-reverse;
    @include s {
      flex-direction: column-reverse;
    }
  }

  .itemImage {
    width: 50%;
    background-color: $bg-color;
    background-size: cover;
    background-position: center;
    @include s {
      min-height: 200px;
      width: 100%;
    }

    &.second {
      width: 68%;
      @include xl {
        width: 65%;
      }
      @include l {
        width: 60%;
      }
      @include m {
        width: 55%;
      }
      @include s {
        width: 100%;
      }
    }
  }
  .info {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.125rem;
    padding: 2.3rem 1.725rem;
    flex-grow: 1;

    @include s {
      padding: 1.5rem 1.25rem;
    }
    @include xs {
      padding: 1.25rem 1rem;
    }
  }
  .percentageFloating {
    display: none;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 100%;
    transform: scale(1.2);

    @include s {
      width: fit-content;
      top: 155px;
      right: 8%;
      transform: scale(1);
    }
    @include xs {
      right: 6%;
    }

    &.second {
      width: auto;
    }

    &.show {
      display: flex;
      justify-content: center;
      &.second {
        right: 3%;
        @include s {
          right: 8%;
        }
        @include xs {
          right: 6%;
        }
      }
    }

    .percentage {
      margin-top: 1.2rem;

      span {
        font-size: .875rem;
      }
    }
  }
}
.column {
  flex-direction: column-reverse;
  align-content: space-between;

  .itemImage {
    width: 100%;
    min-height: 200px;
    background-size: cover;
    background-position: center;
  }
  .info {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.125rem;
    flex-grow: 1;
    padding: 1.5rem 1.25rem;

    @include s {
      padding: 1.5rem 1.25rem;
    }
    @include xs {
      padding: 1.25rem 1rem;
    }
  }
  .percentageFloating {
    display: none;
    position: absolute;
    z-index: 1;
    top: 0;
    top: 155px;
    right: 6%;

    &.show {
      display: flex;
      justify-content: center;
    }

    .percentage {
      margin-top: 1.25rem;

      span {
        font-size: .875rem;
      }
    }
  }
}
.showSoldTokens .soldTokens {
  display: flex;
}
.showItemStatus {
  position: relative;
}
.showItemStatus .status {
  display: flex;
}
.headerInfo {
  display: flex;
  flex-direction: column;
  gap: .35rem;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid $neutrals5;
}
.name {
  @include MediumFont;
  font-size: 1.25rem;
  line-height: 100%;
  font-weight: 700;
  text-transform: uppercase;
}
.location {
  @include MediumFont;
  font-size: .875rem;
  line-height: 100%;
  font-weight: 600;
  color: $primary-color;

  &.secondary {
    color: $text-color;
  }
}
.soldTokens {
  display: none;
  position: relative;
  margin: -.75rem 1rem -.75rem 1rem;

  .label {
    flex-grow: 1;
    z-index: 0;
    background: $bg-color;
    @include dark {background: $dark-light;}
    border-radius: 6.25rem;
    box-shadow: 0 4px 4px $shadow-color;
    margin-right: 2rem;
    padding: .313rem .625rem;
    font-size: .625rem;
    font-weight: 400;
    line-height: 104%;

  }
  .percentage {
    position: absolute;
    top: 0;
    right: 0;
    margin: -.75rem 0;
    margin-left: auto;
  }
}
.propertiesContainer {
  display: flex;
  flex-direction: column;
  gap: .625rem;

  .percentage {
    color: $primary-color;
    text-transform: capitalize !important;
  }
  .propertyLabel {
    font-size: .75rem !important;
    @include xs {
      font-size: .625rem !important;
    }
  }
  .businessModel {
    color: $primary-color;
    text-align: left;
    line-height: 90% !important;
  }
}
.button {
  margin-top: auto;

  &.underReview {
    color: $icon-color;
    background: $ternary-color;
  }

  &:hover {
    opacity: 1;
    background: #0152A3;
    -webkit-transition: background 600ms ease-out;
    -ms-transition: background 600ms ease-out;
    transition: background 600ms ease-out;
    font-weight: 700;
  }
}

.status {
  display: none;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  margin: 1rem;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  @include MediumFont;
  font-size: .875rem;
  width: 700;
  border-radius: 6.25rem;

  &.sold {
    color: $bg-color;
    background: $secondary-color;
  }
  &.opportunity, &.funded, &.underReview, &.seekingFunding {
    background: $light;
  }
  &.underReview {
    color: $gray-fifty;
    @include dark {color: $light-fifty;}
  }
}

.path-stroke {
  path {
    stroke: $icon-color;
  }
}
.path-stroke-fill {
  path {
    &:first-of-type {
      stroke: $icon-color;
    }
    &:last-of-type {
      fill: $icon-color;
    }
  }
}