@import "../../styles/mainStyles.scss";

.section {
  padding: 2rem 0;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin: 0 auto;
  transition: .35s;
  max-width: 25rem;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 1.25rem;

    > span {
      @include TitleFont;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 104%;
      letter-spacing: 0em;
      text-align: center;

      @include xs {
        font-size: 1.25rem;
      }
    }

    .prev {
      position: absolute;
      top: 0;
      left: .125rem;
      &:hover { cursor: pointer;}
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    width: stretch;
    gap: .5rem;

    .from, .to {
      display: flex;
      flex-direction: column;
      > span {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: flex-start;
        white-space: nowrap;
      }
    }
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      width: 100%;

      .left {
        font-size: 1rem;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        @include BoldFont;
        font-size: 2.5rem;
        @include s {
          font-size: 2.25rem;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        gap: 1rem;
        flex-grow: 1;
        height: 100%;
        @include s {gap: .5rem;}
        .inputContainer {
          display: flex;
          align-items: center;
          justify-content: end;
          box-sizing: border-box;
          gap: .25rem;
          width: 90%;

          &.error {
            input {
              color: $error;
              font-weight: 600;
            }
          }

          input {
            display: flex;
            @include MediumFont;
            font-weight: 600;
            letter-spacing: 0em;
            flex-grow: 1;
            height: 3rem;
            text-align: end;
            box-shadow: none;
            border: none;
            outline: none;
            background: none;
            position: relative;
            color: $text-color;
            width: 100%;
            min-width: unset;
            max-width: 100%;

            @include s {
              font-size: 2.25rem;
            }

            &:active,
            &:focus,
            &:focus-within,
            &:focus-visible,
            &:hover {
              border: none;
              box-shadow: none;
              outline: none;
            }
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
              /* display: none; <- Crashes Chrome on hover */
              -webkit-appearance: none;
              margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
          }
        }
      }
      .available {
        font-size: .875rem;
        > span {
          @include MediumFont;
          font-weight: 600;
        }
      }
      .max {
        left: auto;
        font-size: .625rem;
        padding: .325rem .625rem;
        border-radius: .625rem;
        background: #E1E1E1;
        @include dark {
          background: $gray;
        }
        &:hover { cursor: pointer;}
      }
      &.fee {
        > span {
          font-size: .875rem;
          &:last-of-type {
            font-size: .875rem;
            font-weight: 700;
          }
        }
      }
    }
    .lines {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: .5rem;
      > span {
        &:first-of-type, &:last-of-type {
          border-top: 1px solid $gray-fifty;
          width: 50%;
        }
        &.circle {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $primary-color;
          border-radius: 100%;
          width: 1.875rem;
          height: 1.875rem;
          min-width: 1.875rem;
          min-height: 1.875rem;
          svg {
            width: .75rem;
            height: .75rem;
            path {fill: setColor($primary-color)}

          }
          &:hover {
            background: darken($primary-color, 5%);
            -webkit-transition: background 600ms ease-out;
            -ms-transition: background 600ms ease-out;
            transition: background 600ms ease-out;
            cursor: pointer;
          }
          &.clicked {
            svg {
              transform: rotate(180deg);
              transition: transform 600ms;
            }
          }
        }
      }
    }
    .line {
      border-top: 1px solid $gray-fifty;
      width: 100%;
    }
    .errorMsg {
      display: none;
      visibility: hidden;
      @include MediumFont;
      color: $error;
      font-size: .75rem;
      line-height: 104%;
      &.visible {display: block; visibility: visible;}
    }
    .footer, .footer > span {
      @include MediumFont;
      font-size: .75rem;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.01em;
      .link {
        color: $text-color;
      }
    }
  }
}
.modal {
  max-width: 22rem;
}
.accountModal {
  max-width: 20rem;
}
.containerModal {
  > span {
    width: 80%;
  }
}
.success, .failure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;

  > span {
    @include MediumFont;
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 104%;
    text-transform: uppercase;
  }
}
.success > span { color: $success;}
.failure > span { color: $error;}