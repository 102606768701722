@import "../../../../../../styles/mainStyles.scss";

.title {
  @include TitleFont;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 104%;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;

  @include xs {
    font-size: 1.25rem;
  }
}
.body {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 100%;
  justify-content: start;

  .textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    padding: .75rem 0;

    span {
      @include RegularFont;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 104%;

      &.amount {
        font-size: 2.5rem;
        font-weight: 600;
      }
    }
  }
}
.properties {
  display: flex;
  flex-direction: column;
  gap: .25rem;
  padding: .375rem 0;
  border-top: 1px solid $neutrals5;
  border-bottom: 1px solid $neutrals5;
  width: 100%;

  .property {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include RegularFont;
    font-size: 1rem;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: 0em;

    span {
      @include MediumFont;
      font-weight: 700;
      color: $primary-color;
    }
  }
}
.footer {
  display: flex;
  @include RegularFont;
  font-size: .75rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}
.terms {
  display: inline-block;
  gap: .125rem;
  width: 100%;

  @include RegularFont;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.01rem;
  color: $text-color;

  .link {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.01rem;
    text-decoration-line: underline;
    color: $text-color;

    &:hover {
      cursor: pointer;
      color: $gray;
      @include dark {color: $light;}
    }
  }
}
.button {
  width: 100%;
}