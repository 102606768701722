@import '../../../styles/mainStyles.scss';

.download {
  path {
    fill: setColor($base-color);
    @include dark {fill: setColor($gray); }
  }
}

.containerExportingModal {
  padding-bottom: 2rem;
  max-width: 19rem;
  > span {
    &:last-of-type {
      font-size: 1rem;
      width: 70%;
      line-height: 120%;
      @include s {
        font-size: .875rem;
        width: 85%;
      }
    }
  }
}