
@import '../../styles/mainStyles.scss';

.container {
  padding-top: 1rem;
  padding-bottom: 3rem;
  width: stretch;
  gap: 1rem;

  .line {
    width: stretch;
    margin: -.5rem 0 1rem 0;
    border-bottom: 1px solid rgba(67, 67, 81, 0.3);
    @include dark {border-color: $light-fifty;}
  }
}

.path {
  &-stroke {
    @include dark {path, ellipse, circle {stroke: $light;}}
  }
  &-fill {
    @include dark {path {fill: $light;}}
  }
}