@import '../../styles/mainStyles.scss';

.loader {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(0deg, $bg-color 33%, $primary-color 100%);
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 50%;
  background: $bg-color;
}
@keyframes rotation {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg)}
}