@import "../../../../../../styles/mainStyles.scss";

.title {
  @include TitleFont;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2.5rem;
  letter-spacing: 0em;
  text-align: center;
}

.stripe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  gap: 10px;

  width: 326px;
  height: 423.73px;

  background: rgba(28, 74, 118, 0.7);
  opacity: .7;
  border-radius: 10px;

  color: $light;
  font-size: 2rem;
}