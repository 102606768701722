@import "../../styles/mainStyles.scss";

.title {
  @include TitleFont;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2.5rem;
  letter-spacing: 0em;
  text-align: center;
}

.onRamper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  gap: 10px;
  margin: 0 auto;

  width: 326px;
  height: 423.73px;

  background: rgba(28, 74, 118, 0.7);
  opacity: .7;
  border-radius: 10px;

  color: $light;
  font-size: 2rem;
}
.button {
  width: 75%;
  margin: 0 auto;
}

.price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .label {
    @include RegularFont;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 100%;
  }

  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    .value {
      @include BoldFont;
      font-size: 2.5rem;
      font-weight: 600;
      line-height: 130%;
      letter-spacing: 0em;
      text-align: left;
    }
    .currency {
      @include RegularFont;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: 0em;
      text-transform: uppercase;
      margin-top: .5rem;
    }
  }
  .officialCurrency {
    @include RegularFont;
    font-size: 1rem;
    font-weight: 400;
    line-height: 105%;
    letter-spacing: 0em;
  }
}

.fundsModal {
  > span {
    width: 80%;
  }
}