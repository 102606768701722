@import "../../../../styles/mainStyles.scss";

.wrapper {
	border-radius: 100%;
  overflow: hidden;
}
.singleChart {
  width: 6.25rem;
  justify-content: space-around;
}

.circularChart {
  display: block;
  margin: auto;
}
.circleBg {
  fill: $bg-color;
  stroke: rgba(96, 96, 121, 0.14);
  stroke-width: 7.5;
  @include dark {
    fill: $dark-light;
    stroke: $light-twenty-five;
  }
}
.circle {
  stroke-width: 3.25;
  stroke-linecap: round;
  animation: progress 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}
@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
.circularChart.primary .circle {
  stroke: $primary-color;
  fill: none;
  &.empty {
    stroke: none;
  }
  &.completed {
    fill: $primary-color;
  }
}
.circularChart.secondary .circle {
  stroke: $secondary-color;
  fill: none;
  &.empty {
    stroke: none;
  }
  &.completed {
    fill: $secondary-color;
  }
}
.percentage {
	@include MediumFont;
  font-size: .5em;
  text-anchor: middle;
	font-weight: 500;
  line-height: 100%;
	fill: $text-color;

  &.completed {
    fill: $light;
  }
}