@import "../../../../styles/mainStyles.scss";

.personalInformation {
  height: auto;
  padding: 0;
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;

  > .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .5rem;
    width: 100%;
    padding-bottom: .5rem;

    .prev {
      transform: rotate(90deg);
      cursor: pointer;
    }
    span {
      @include RegularFont;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 104%;
      letter-spacing: 0em;
    }
    .button {
      border: none;
      outline: none;
      background: none;
      margin-left: auto;
      padding: 0;

      @include RegularFont;
      font-size: 1rem;
      font-weight: 500;
      line-height: 105%;
      letter-spacing: 0em;
      color: $gray-fifty;
      @include dark {color: $light-fifty;}

      &.active {
        color: $green;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: .75rem;
  padding: .5rem 0;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;

  &.border {
    border-bottom: 1px solid #43435180;
  }

  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .person {
      @include RegularFont;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 104%;
      letter-spacing: 0em;
    }
    .arrow {
      transition: transform $animation-timing-default;
      cursor: pointer;
      margin-right: .375rem;
    }

    &.selected {
      .person {
        color: $primary-color;
      }
      .arrow {
        transform: rotate(180deg);
      }
    }
  }
  .body {
    display: none;
    flex-direction: column;
    gap: .75rem;
    width: stretch;
    transition: 1s;
    animation: fadeIn 1s;

    &.active {
      display: flex;
      animation: fadeIn 1s;
    }


    .row, .rowReverse {
      display: flex;
      flex-direction: row;
      width: stretch;
      max-width: 100%;
      gap: 1rem;

      > div {
        &.city {
          @include xs {
            width: calc(60% - .5rem);
          }
        }
        &:last-of-type {
          width: 30%;
          @include xs {
            width: calc(40% - .5rem);
          }
        }
        &.street {
          width: 51.111%;
        }
        &.number, &.floor, &.department {
          width: 13.333%;
        }
        @include s {
          &.street { width: 100%;}
          &.number, &.floor, &.department { width: calc(33% - .625rem);}
        }
      }
      @include s {
        flex-wrap: wrap;
      }
    }
    .rowReverse {
      > div {
        &:last-of-type {
          width: 74%;
          @include xs {
            width: calc(64% - .5rem);
          }
        }
      }
    }

    .dropdown {
      width: 100%;
      .head {
        height: 2.5rem;
      }
      .arrow {
        svg {
          right: .125rem;
          path {
            stroke: $text-color;
          }
        }
      }
    }
  }
}