@import '../../../../styles/mainStyles.scss';

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
  height: fit-content;
  overflow: hidden;
  padding: 0 1rem;

  svg {
    @include m {
    }
    @include s {
      width: 40%;
      height: 40%;
    }
    @include xs {
      width: unset;
      height: unset;
    }
    path {
      fill: $primary-color;
    }
  }

  @include m {
    padding: 0 1rem;
    gap: .75rem;
  }
  @include s {
    padding: 0 2rem;
  }
  @include xs {
    gap: .5rem;
    padding: 0 .25rem;
  }
}

.action {
  @include BoldFont;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 104%;
  letter-spacing: 0em;
  text-align: center;
  width: 75%;

  @include m {
    width: 90%;
  }
  @include xs {
    font-size: 1rem;
    width: 100%;
  }
  @include xxs {
    font-size: .75rem;
  }
}
.description {
  @include RegularFont;
  font-size: .875rem;
  font-weight: 500;
  line-height: 104%;
  letter-spacing: 0em;
  text-align: center;
  width: 75%;

  @include m {
    width: 90%;
  }
  @include s {
    font-size: .75rem;
    width: 70%;
  }
  @include xs {
    width: 85%;
  }
  @include xxs {
    font-size: .625rem;
    width: 100%;
  }
}