$lightness-sensitive: 50;

$primary-color: #0075EA;
$secondary-color: #003061;
$ternary-color: #606079;
$orange: #F98633;
$primary-gradient: linear-gradient(81deg, #000 -8.93%, #06C 86.08%);
$secondary-gradient: linear-gradient(135deg, #0075EA 0%, #2CD78E 100%);
$purple-light: rgba(47, 7, 111, 0.5);
$purple-blue-gradient: linear-gradient(90deg, #D80587 0%, #2F076F 100%);
$real-estate-gradient: linear-gradient(90deg, #6BB2FF 0%, #68A8FE 8%, #5B79FB 50%, #535BF9 82%, #5051F9 100%);

$primary-button-color: $primary-color;
$secondary-button-color: $primary-color;
$call-to-action-button-color: #2CD78E;
$header-bg-color: #F6F0F0;
$footer-bg-color: #1E1F25;
$icon-color: #fff;
$horizonte: #19D987;
