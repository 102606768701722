@import "../../../../styles/mainStyles.scss";

.referralCode {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: stretch;
  position: relative;
  overflow: visible;
  padding: .75rem 1rem;
  border-radius: .625rem;
  background: $bg-color;
  @include dark {background: $dark-light;}

  > span {
    @include MediumFont;
    font-style: normal;
    font-weight: 700;
    line-height: 104%; /* 16.64px */
  }

  .code {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;

    span {
      @include MediumFont;
      font-style: normal;
      font-weight: 700;
      line-height: 104%; /* 16.64px */
      &.copied {
        line-height: 135%;
        animation: fadeIn .6s;
        color: $success;
      }
    }

    svg {
      height: fit-content;
      &:hover {cursor:pointer;}
      path {fill: $primary-color;}
    }
  }
}

.onBoarding {
  top: 1.5rem;
  left:0;
  right:0;
  margin: 0 auto;
  @include s {
    top: 2.25rem;
  }
}