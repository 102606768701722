@import "../../styles/mainStyles.scss";

.section {
  padding: 2rem 0;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin: 0 auto;
  transition: .35s;
  max-width: 25rem;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 1.25rem 1rem;
  gap: 1rem;
  > span {
    &:first-of-type {
      @include TitleFont;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 104%;
      letter-spacing: 0em;
      text-align: center;

      @include xs {
        font-size: 1.25rem;
      }
    }

    &:nth-of-type(2) {
      @include RegularFont;
      font-weight: 400;
      line-height: 104%;
      letter-spacing: 0em;
      text-align: center;

      @include xs {
        font-size: .875rem;
      }
    }
  }

  .prev {
    position: absolute;
    top: 0;
    left: .125rem;
    &:hover { cursor: pointer;}
  }
}
.form {
  display: flex;
  flex-direction: column;
  width: stretch;
  gap: 1rem;
  min-height: 18rem;
}
.dropdown {
  flex-grow: 0;
}
.button {
  margin-top: auto;
  width: 100%;
}
.uploader {
  padding: 1.75rem 0;
  > div {
    flex-direction: column;
    > div {
      align-items: center !important;
    }
  }
}
.rowsData {
  display: flex;
  flex-direction: column;
  gap: .75rem;
  width: stretch;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span {
      line-height: 104%;
      &:first-of-type {
        @include RegularFont;
        font-weight: 400;
      }
      &:last-of-type {
        @include MediumFont;
        font-weight: 600;
      }
    }
  }
}
.circle, .add {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: $primary-color;
  &.loading {
    background: #E6E5E5;
  }
}
.add {
  margin: 0 auto;
  width: 30px;
  height: 30px;
  background: $primary-color;
  &:hover {cursor: pointer;}
  svg {
    width: 10px;
    height: 10px;
    path {
      fill: $white;
      fill-opacity: 1;
    }
  }
}
.files {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: stretch;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: .5rem;
    padding: .125rem .25rem;
    border: 1px solid #DBDBDB;
    border-radius: .25rem;
    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .5rem;
      font-size: .75rem;
      font-weight: 400;
      line-height: 104%;
      &:last-of-type {
        margin-left: auto;
        color: $primary-color;
        &:hover { cursor: pointer;}
      }
    }
  }
}
.acceptedFiles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: stretch;
  gap: .5rem;
  > span {
    @include MediumFont;
    font-size: .75rem;
    font-weight: 500;
    line-height: 104%;
  }
  ol {
    margin: 0;
    padding-left: 1rem;
    font-size: .75rem;
    > li {
      color: $gray;
      @include dark {color: $light;}
      line-height: 130%;
    }
  }
}
.bankAccountContainer {
  display: flex;
  flex-direction: column;
  gap: .5rem;

  .bankAccount {
    padding: 1px;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    background: $gray-fifty;
    border-radius: .675rem;

    .box {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1.25rem;
      border-radius: .625rem;
      width: auto;
      height: auto;

      background: $bg-color;
      z-index: 500;

      @include xs {
        padding: 1rem;
        height: fit-content;
      }

      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 100%;

        @include s {
          width: 3rem;
          height: 3rem;
        }
        @include xs {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
      .textContainer {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        background: $bg-color;
        @include s {
          gap: .25rem;
        }
        .name {
          @include MediumFont;
          font-size: 1.25rem;
          font-weight: 500;
          line-height: 100%;
          letter-spacing: 0em;
          text-align: left;

          @include xs {
            font-size: 1rem;
          }
        }
        .description {
          display: flex;
          flex-direction: row;
          gap: .5rem;
          @include RegularFont;
          font-size: 1rem;
          font-weight: 500;
          line-height: 100%;
          letter-spacing: 0em;
          text-align: left;
          color: $gray-fifty;
          @include dark {color: $light-fifty;}

          @include xs {
            font-size: .875rem;
          }
        }
      }
      .right {
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .arrow {
          margin-left: auto;
          transform: rotate(90deg);

          line {
            fill: $gray-fifty;
          }
        }
      }
    }

    &:hover {
      cursor: pointer;
      background: linear-gradient(rgb(0 0 0/5%) 0 0);

      .box {
        background-image: linear-gradient(rgb(0 0 0/5%) 0 0);
        .textContainer {
          background-image: linear-gradient(rgb(0 0 0/5%) 0 0);
        }
      }
      @include dark {
        background: $light;
        .box {
          background-image: $light;
          .textContainer {
            background-image: $light;
          }
        }
      }
    }
  }
}
.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .5rem 0 1rem;
  gap: 1rem;
  width: 100%;
  max-width: 25rem;
  margin: 0 auto;

  .row, .projectData {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    @include RegularFont;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0em;
    width: 100%;

    .inputContainer {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      outline: none;
      gap: .25rem;

      &.error {
        > span {
          color: $error;
          font-weight: 600;
        }
      }

      input {
        display: flex;
        @include MediumFont;
        font-weight: 600;
        letter-spacing: 0em;
        flex-grow: 1;
        height: 4rem;
        min-width: 0;
        width: fit-content;
        max-width: 100%;
        text-align: end;
        box-shadow: none;
        border: none;
        outline: none;
        background: none;
        position: relative;

        &:active,
        &:focus,
        &:focus-within,
        &:focus-visible,
        &:hover {
          border: none;
          box-shadow: none;
          outline: none;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
          /* display: none; <- Crashes Chrome on hover */
          -webkit-appearance: none;
          margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }
    }
  }
  .line {
    border: 1px solid $primary-color;
    width: 100%;
  }
  .available {
    @include MediumFont;
    font-size: .875rem;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0em;

    > span {
      @include RegularFont;
      font-weight: 500;
      margin-right: .25rem;
    }

    @include xs {
      padding-bottom: 3rem;
    }
  }
}
.modal {
  max-width: 22rem;
}
.containerModal {
  > span {
    width: 80%;
  }
}
.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;

  > span {
    @include MediumFont;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 104%;
    text-transform: uppercase;
    color: $success;
  }
}