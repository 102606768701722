@import '../../styles/mainStyles.scss';

.section {
  position: relative;
}
.container {
  gap: 2.5rem;
  width: stretch;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;

  .title {
    @include bold-title;
  }
  .filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;
    margin-left: auto;

    .separator {
      height: 2.5rem;
      text-align: center;
      border-left: 1px solid $light-seventy-five;
    }
    .orderButton {
      display: flex;
      align-items: center;
      gap: .75rem;
      @include MediumFont;
      font-size: .875rem;
      line-height: 100%;
      font-weight: 600;
      svg {
        transition: $animation-timing-default;
        &:hover {
          cursor: pointer;
        }
      }
      &.descending {
        svg {
          // transition: $animation-timing-default;
          transform: rotate(180deg);
        }
      }
      @include m {
        font-size: .75rem;
      }
    }
    @include m {
      gap: 1rem;
      margin: 0;
    }
    @include s {
      overflow: hidden;
      overflow-x: scroll;
      width: 100%;
      padding-bottom: .625rem;
    }
  }

  @include m {
    gap: .5rem;
    flex-direction: column;
    align-items: flex-start;
  }
}
.itemRow {
  display: flex;
  flex-direction: row;
  margin: -1rem 0 -1rem -1rem;
  padding: 1rem 0 3rem 1rem;
  overflow: hidden;

  @include xs {
    margin: -1.125rem;
    padding: 1.125rem 1.125rem 1.5rem 1.125rem;
  }

  .emptyState {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 40%;
    gap: 1rem;
    padding: 2rem 0;

    @include m {
      width: 70%;
    }
    @include s {
      width: 85%;
    }
    @include xs {
      padding: 1.5rem 0;
      width: 95%;
    }

    img {
      @include s {
        max-width: 25rem;
      }
      @include xs {
        max-width: 20rem;
      }
    }

    .header {
      @include MediumFont;
      text-align: center;
      font-size: 1.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 116.9%; /* 2.19188rem */

      @include s {
        font-size: 1.75rem;
      }
      @include xs {
        font-size: 1.5rem;
      }
    }
    .footer {
      @include MediumFont;
      text-align: center;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 116.9%; /* 1.31513rem */

      @include s {
        font-size: 1rem;
      }
      @include xs {
        font-size: .875rem;
      }
    }
  }
}
.slider {
  width: stretch;
  overflow: visible;
}
.swiperSlide {
  border-radius: .5rem;
}
.bulletActive {
  background: $primary-color !important;
}
.itemCard {
  display: flex;
  justify-content: space-between;
  transition: .5s;
  width: 100%;
  background: $bg-color;
  @include dark {
    background: $dark-light;
  }
  &.second, &.straightCorners {
    border-radius: .313rem;
  }
}
.bulletActive {
  background: $primary-color;
}