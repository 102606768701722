@import "../../../../styles/mainStyles.scss";

.card {
  padding: 1px;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: $primary-gradient;

  .investmentBox {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;

    padding: 1.5rem 1.75rem;
    background: $bg-color;
    @include dark {background: $dark-light;}
    border-radius: .625rem;
    &.straightCorners {
      border-radius: .313rem;
    }
    @include s {
      gap: .75rem;
    }
    @include xs {
      gap: .5rem;
      padding: 1rem;
    }
    .label {
      text-transform: capitalize;
    }

    .redirect {
      display: flex;
      gap: .5rem;
      align-items: center;
      .buttonRedirect {
        &:hover {
          cursor: pointer;
        }
        svg {
          width: .875rem;

          path {
            fill: $secondary-color;
          }
        }
      }
    }

    .line {
      margin: 1rem 0;
      width: 100%;
      border: 2px solid #43435180;

      @include s {
        margin: .75rem 0;
      }
      @include xs {
        margin: .5rem 0;
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .button {
        @include BoldFont;
        font-size: 1.25rem;
        height: 3.813rem;
        font-weight: 600;
        margin-top: auto;

        @include xs {
          @include MediumFont;
          font-size: 1rem;
          height: 2.75rem;
        }
      }

      @include s {
        gap: 1rem;
      }
    }

  }
}

// path - fill: projectDuration, minimalInvestment, estimatedPeriod, projectRateOfReturn, star
.path-fill {
  path {
    fill: $icon-color;
  }
}
// path - stroke: tokenPrice, tokensCreated, availableTokens,
.path-stroke {
  path {
    stroke: $icon-color;
  }
}
.path-stroke-fill {
  path {
    &:first-of-type {
      stroke: $icon-color;
    }
    &:last-of-type {
      fill: $icon-color;
    }
  }
}
.bold {
  @include BoldFont;
}
.primary {
  color: $primary-color;
  font-size: 1.625rem !important;
  @include s {
    font-size: 1.25rem !important;
  }
}