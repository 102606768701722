@import "../../../../styles/mainStyles.scss";

.myWallet {
  display: flex;
  flex-direction: column;
  height: auto;
  width: stretch;
  position: relative;
  overflow: visible;
  @include dark {background: $dark-light;}

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 1rem;
    width: stretch;
    gap: .5rem;
    color: setColor($gray);
    background: linear-gradient(180deg, rgb(1, 98, 194) 0%, rgb(5, 48, 92) 100%);
    border-top-right-radius: .625rem;
    border-top-left-radius: .625rem;

    @include s {
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem .875rem;
    }

    .wallet {
      display: flex;
      flex-direction: column;
      gap: .25rem;

      > span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // width: 100%;
        @include MediumFont;
        font-size: 1rem;
        font-weight: 500;
        line-height: 105%;
        letter-spacing: 0em;
        text-align: left;
        gap: .5rem;
        @include xs {
          font-size: .875rem;
        }
      }
      .address {
        > svg {
          path {
            fill: $call-to-action-button-color;
          }
        }
      }
    }

    .address {
      display: flex;
      align-items: center;
      gap: .25rem;
      width: fit-content;

      &:hover {
        cursor: pointer;
      }

      &.copied {
        animation: fadeIn .6s;
        color: $success;
        min-height: 20px;
        &:hover {
          cursor: unset;
        }
      }

      @include s {
        font-size: .875rem;
      }
      @include xs {
        font-size: .75rem;
      }
      svg {
        path {
          fill: $primary-color;
        }
      }
    }
  }
  .rows {
    display: flex;
    flex-direction: column;
    width: stretch;
    padding: .5rem 1rem;

    .row {
      display: flex;
      gap: 1rem;
      padding: .5rem 0;
      align-items: center;
      background: $bg-color;
      @include dark {background: $dark-light;}

      &.loading {
        background: $neutrals6;
      }
      &.pesos {
        border-top: 1px solid $gray-fifty;
      }
      &:last-of-type {
        border-bottom-left-radius: .625rem;
        border-bottom-right-radius: .625rem;
      }
      .currency {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.125rem;
        > span {
          line-height: 104%;
          &:first-of-type {
            @include MediumFont;
            font-size: .875rem;
            font-weight: 600;
          }
          &:nth-child(2) {
            @include RegularFont;
            font-size: .75rem;
            font-weight: 400;
          }
        }
      }
      .balance {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: auto;
        > span {
          line-height: 104%;
          &:first-of-type {
            @include MediumFont;
            font-size: 1.25rem;
            font-weight: 500;
          }
          &:nth-child(2) {
            @include RegularFont;
            font-size: 1rem;
            font-weight: 300;
          }
        }
      }
    }
  }
}
.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: .75rem;
  position: relative;
  width: 50%;

  @include s {
    width: 100%;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
  }

  .button {
    align-items: center;
    border-radius: .188rem !important;
    padding: 0 .5rem;
    width: 33.33%;
    gap: .25rem;
    height: 2.5rem;

    &.validating {
      > span, svg {display: none;}
      @include Skeleton;
    }
    &:disabled {
      svg {
        &.swap {
          path {
            fill: setColor($light);
          }
        }
      }
    }

    @include s {
      box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
    }
    @include xs {
      svg { display: none;}
    }
    svg {
      min-width: 1.25rem;
      &.withdrawal, &.deposit {
        path {
          stroke: setColor($light);
        }
      }
      &.swap {
        path {
          fill: setCallToActionColor($call-to-action-button-color);
          stroke: none;
        }
      }
    }
    &:hover {
      .swap {
        path {
          fill: setColor(darken($call-to-action-button-color, 10%));
        }
      }
    }
    span {
      @include MediumFont;
      align-items: center;
      font-size: 1rem;
      font-weight: 600;
      line-height: 105%;
      letter-spacing: 0em;
      @include s {
        font-size: .875rem;
      }
    }
  }
}

.onBoardingWallet {
  left: 20%;
  @include s {
    top: -4.5rem;
    left: 15%;
  }
  @include xs {
    left: 10%;
  }
}
.onBoardingDeposit {
  top: -50%;
  left: -60%;

  @include s {
    top: -5.5rem;
    left: 0rem
  }
}