@import '../../../styles/mainStyles.scss';

.dropdown, .button {
  min-width: 5.5rem;
}
.dropdown {
  width: 100%;
  height: 100%;
  .head {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: .375rem;
    @include MediumFont;
    font-size: .875rem;
    border-radius: $default-button-border-radius;
    padding: .75rem .375rem;
    background: $base-color;
    color: setColor($base-color);
    @include dark {
      background: $dark-light;;
      color: setColor($dark-light);
    }
    box-shadow: none;
    width: stretch;
    height: fit-content;

    &:hover,
    &:active,
    &:focus-within,
    &:focus-visible {
      outline: none;
      box-shadow: none;
    }
    &:hover,
    &:active {
      background: darken($base-color, 10%);
      -webkit-transition: background 600ms ease-out;
      -ms-transition: background 600ms ease-out;
      transition: background 600ms ease-out;
      @include dark {
        background: darken($dark-light, 10%);;
      }
    }

  }
  .option, .selection {
    font-size: .875rem;
    line-height: 115%;
  }
  .option {
    padding: .25rem;
    > span {
      font-size: .875rem;
    }
    &:hover {
      background: $primary-color;
      -webkit-transition: background 600ms ease-out;
      -ms-transition: background 600ms ease-out;
      transition: background 600ms ease-out;
      > span {
        color: setColor($primary-color);
        font-weight: 500;
      }
    }
  }
  .body {
    top: calc(100% + .5rem);
    padding: .25rem 0;
    width: fit-content;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: .25rem;
  }
  .arrow {
    position: relative;
    transform: unset;
    top: unset;
    right: unset;
    svg {
      top: unset;
      right: unset;
      path {
        stroke: $text-color;
      }
    }
  }
}

.button {
  position: relative;
  display: flex;
  @include MediumFont;
  font-size: .875rem;
  border-radius: $default-button-border-radius;
  padding: .625rem .375rem;
  justify-content: center;
  align-items: center;
  gap: .625rem;
  background: $base-color;
  color: setColor($base-color);
  @include dark {
    background: $dark-light;
    color: setColor($dark-light);
  }

  input {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    opacity: 0;
  }
  &:hover {
    cursor: pointer;
    background: darken($base-color, 10%);
    -webkit-transition: background 600ms ease-out;
    -ms-transition: background 600ms ease-out;
    transition: background 600ms ease-out;
    @include dark {background: darken($dark-light, 10%);}
  }

  &:disabled {
    opacity: .8;
    &:hover {
      cursor: not-allowed;
      opacity: unset;
    }
  }

  .calendar {
    path {
      fill: setColor($base-color);
      @include dark {fill: setColor($dark-light)}
    }
  }
}

.dateFilter {
  display: flex;
  flex-direction: row;
}
.clearButton {
  display: none;
}