@import "../../../../styles/mainStyles.scss";

.card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
  padding: 1.25rem 1.25rem 1.75rem;
  width: fit-content;
  height: 100%;
  background: $bg-color;
  z-index: 0;

  > span {
    @include RegularFont;
    font-size: .75rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 104%;
  }

  .skeleton {
    width: 6.25rem;
    height: 6.25rem;
  }
}