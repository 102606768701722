@import '../../styles/mainStyles.scss';

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingSpinner {
  width: 1rem;
  height: 1rem;
  border: 3px solid $neutrals4;
  border-top: 3px solid transparent !important;
  border-radius: 50%;
  animation: spinner 1.75s linear infinite;
}

.loader {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(0deg, $bg-color 33%, $primary-color 100%);
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
.loader::after {
  // content: '';
  // box-sizing: border-box;
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  // width: .975rem;
  // height: .975rem;
  // border-radius: 50%;
  // background: transparent;
}
@keyframes rotation {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg)}
}