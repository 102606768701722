@import "../../styles/mainStyles.scss";

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  flex-grow: 0;
  gap: .25rem;

  @include m {
    justify-content: flex-start;
    overflow-x: auto;
    margin: 0;
    padding-bottom: .25rem;

    @include scrollBar;
    &::-webkit-scrollbar-track:horizontal {
      margin: 0 .75rem;
    }
  }

  .sections {
    display: flex;
    flex-direction: row;

    @include m {
      // width: 80%;
    }
  }

  .section {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: .5rem;
    height: 1.5rem;
    max-height: 1.5rem;
    flex-grow: 1;
    padding: .5rem 0;
    border-radius: .625rem;
    min-width: 8.75rem;

    @include m {
      min-width: 7.75rem;
    }

    &.active {
      &::before {
        content : "";
        position: absolute;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        bottom: 0;
        height: 1px;
        width: 3rem;  /* or 100px */
        border-bottom: 2px solid $primary-color;
        @include dark {
          width: 3.5rem;  /* or 100px */
        }
      }
    }

    span {
      @include MediumFont;
      font-size: .875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 104%; /* 14.56px */
      color: $gray;
      @include dark {color: $light;}
      @include s {font-size: .75rem;}
    }

    &:hover {
      cursor: pointer;
      background: $base-color;
      @include dark {
        background: $gray;
      }
    }

    .notification {
      display: flex;
      width: 1.325rem;
      height: 1.325rem;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: $yellow;
      @include RegularFont;
      font-size: .75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 104%; /* 10.4px */

    }
  }
}