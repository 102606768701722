@import "../../../../../../styles/mainStyles.scss";

.header {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .title {
    @include TitleFont;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 104%;
    letter-spacing: 0em;
    text-align: center;

    @include s {
      font-size: 1.75rem;
    }
    @include xs {
      font-size: 1.25rem;
    }
  }
  .bought {
    @include RegularFont;
    font-size: 1rem;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0em;
    text-align: center;
  }
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .5rem 0 2rem;
  gap: 1rem;

  .projectData {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    @include RegularFont;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0em;

    > span {
      @include MediumFont;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: 0em;

      &:first-of-type {
        font-size: 2.5rem;
        @include s {
          font-size: 2rem;
        }
      }
      &:last-of-type {
        font-size: 1.875rem;
        @include s {
          font-size: 1.75rem;
        }
      }
    }
    @include s {
      font-size: 1.75rem;
    }
  }
  .line {
    border: 1px solid $primary-color;
    width: 100%;
  }
  .price {
    @include MediumFont;
    font-size: .875rem;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0em;
  }
  .button {
    width: 100%;
  }
}