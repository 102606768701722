@import "../../../styles/mainStyles.scss";


.container {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: .75rem;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  transition: .35s;
  max-width: 25rem;
}
.form {
  display: flex;
  flex-direction: column;
  width: stretch;
  gap: 1.5rem;

  .button {
    margin: 0 auto;
    width: 100%;
    max-width: 25rem;
  }
}
.modal {
  max-width: 22rem;
}
.accountModal {
  max-width: 20rem;
}
.containerModal {
  > span {
    width: 80%;
  }
}
.pending, .success, .failure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;

  > span {
    @include MediumFont;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 104%;
    text-transform: uppercase;
    color: $yellow;
  }
}
.success > span { color: $success;}
.failure > span { color: $error;}