@import "../../styles/mainStyles.scss";

.section {
  background-image: url('../../assets/images/tokenization-background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  padding-top: 3rem;
  padding-bottom: 8rem;
  gap: 1.75rem;
  width: stretch;

  @include m {
    padding-top: 2rem;
    padding-bottom: 6rem;
    flex-direction: column;
  }
  @include s {
    padding-bottom: 4rem;
    gap: 1.5rem;
  }

  video {
    width: 55%;
    height: auto;
    @include m {
      width: 100%;
    }
  }
  .textWrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 45%;

    @include m {
      gap: 1.5rem;
      width: 100%;
    }

    span {
      &:first-of-type {
        @include BoldFont;
        font-size: 2.75rem;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: 0em;
        text-align: left;
        color: $light;
        width: 60%;

        @include m {
          font-size: 2.5rem;
          width: 80%;
        }
        @include s {
          font-size: 2rem;
        }
        @include xs {
          font-size: 1.75rem;
          width: 100%
        }
      }
      &:last-of-type {
        @include RegularFont;
        font-size: 1.375rem;
        font-weight: 400;
        line-height: 115%;
        letter-spacing: 0em;
        text-align: left;
        color: $light;

        @include m {
          font-size: 1.25rem;
          width: 90%;
        }
        @include s {
          font-size: 1.125rem;
        }
        @include xs {
          font-size: 1rem;
          width: 100%;
        }
      }
    }
  }
}