@import "../../../../styles/mainStyles.scss";

.section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: .75rem;
      padding-top: .5rem;
      .title {
        @include TitleFont;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 104%;
      }
      .tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          cursor: pointer;
        }
        .tab {
          position: relative;
          padding: 0 1rem .25rem;
          border-bottom: 2px solid transparent;
          @include m {padding: 0 .5rem .25rem;}
          @include s {padding: 0 .25rem .25rem;}
          > span {
            @include MediumFont;
            font-size: .75rem;
            font-style: normal;
            font-weight: 500;
            line-height: 104%; /* 12.48px */
          }
          &.selected {
            &::before {
              content : "";
              position: absolute;
              display: flex;
              justify-content: center;
              margin: 0 auto;
              left: 50%;
              -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
              bottom: 0;
              height: 1px;
              width: 3rem;  /* or 100px */
              border-bottom: 2px solid $primary-color;
            }
          }
        }
      }
      .items {
        display: flex;
        gap: .25rem;
        justify-content: start;
        @include RegularFont;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        > span {
          @include MediumFont;
          font-weight: 700;
        }
      }
    }
  }

  .body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.25rem;
    width: stretch;

    .card {
      height: auto;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      padding: 1rem 1rem 0;
      align-items: flex-start;
      overflow-x: auto;

      @include s {
        padding: .75rem .5rem 0;
      }

      .headerTable {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: stretch;
        flex-grow: 1;
        gap: 1rem;
        padding: 0 .25rem;

        > span {
          @include MediumFont;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: 104%;
        }

        .pageSize {
          margin-left: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: .5rem;
          > span {
            @include RegularFont;
            font-size: .75rem;
            line-height: 110%;
            max-width: 4.25rem;
          }
          .dropdown {
            min-width: 3.125rem;
            .head {
              border-radius: .25rem;
              padding: .5rem;
            }
            .body {
              padding: .25rem 0;
              margin: 0;
              overflow-y: hidden;
            }
          }
        }
      }

      @include scrollBar;
      &::-webkit-scrollbar-track:horizontal {
        margin: 0 .75rem;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        min-width: 750px;
        tr {
          line-height: 100%;
          &:nth-of-type(2) {
            td {
              padding-top: .5rem;
            }
          }
          &:last-of-type {
            td {
              padding-bottom: 1rem;
            }
          }
          span {
            @include RegularFont;
            font-size: .75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 104%;
          }

          th {
            line-height: 104%;
            border-bottom: 1px solid rgba(67, 67, 81, 0.20);
            @include dark {border-color: $light-fifty;}
            text-align: start;
            padding-bottom: .5rem;

            &:first-of-type, &:last-of-type {
              width: 0%;
            }
            &.check {
              input {
                margin-right: .5rem;
                vertical-align: middle;
              }
            }
            &.name {
              width: 17.5%;
            }
            &.group {
              width: 12%;
            }
            &.email {
              width: 18%;
            }
            &.kyc, &.phone {
              width: 17.5%;
            }
            &.dateJoined {
              width: 12.5%;
            }
          }
          td {
            padding: .25rem 0;
            text-align: start;

            &.check {
              width: 0%;
              input {
                margin-right: .5rem;
                vertical-align: middle;
              }
            }
            &.name {
              span {
                @include MediumFont;
                font-weight: 700;
                line-height: 0;
              }
            }
            &.group {
              span {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: .25rem .5rem;
                border-radius: .675rem;
                font-size: .675rem;
                width: 60%;
                background: $primary-color;
                color: setColor($primary-color);

                @include m {
                  width: 70%;
                }
              }
            }
            &.kyc {
              span {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: .25rem .5rem;
                border-radius: .675rem;
                font-size: .675rem;
                width: 40%;
                background: $yellow;

                @include m {
                  width: 70%;
                }

                &.approved {
                  background: $success;
                }
                &.pending {
                  background: $yellow;
                }
                &.rejected {
                  color: $bg-color;
                  background: $error;
                }
              }
            }
            &.actions {
              text-align: end;
              span {
                @include RegularFont;
                color: $primary-color;
                text-align: center;
                font-size: .625rem;
                font-style: normal;
                font-weight: 400;
                line-height: 104%; /* 10.4px */
                text-decoration-line: underline;

                &:hover {
                  cursor: pointer;
                }
              }
              svg {
                path {fill: $light;}
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
          th, td {
            @include s {
              text-align: center;
            }
          }

          &.emptyState {
            section {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: auto;
              gap: .75rem;
              flex-grow: 1;
              padding: 2rem 0;

              span {
                @include MediumFont;
                font-weight: 500;
                font-size: .875rem;
                line-height: 104%;
                color: $gray-fifty;
                @include dark {color: $light-fifty;}
              }
            }
          }
        }
        tfoot {
          border-top: 1px solid rgba(67, 67, 81, 0.20);
          @include dark {border-color: $light-fifty;}
          tr {
            line-height: 100%;
            td {
              padding: 1rem 0 !important;
            }
          }
        }
      }
    }
  }
}

input[type=checkbox]{
  accent-color: $primary-color;
  color: #fff;
}