@import "../../../../styles/mainStyles.scss";

.body {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1.25rem;
  @include s {gap: 1rem;}

  span {
    @include RegularFont;
    font-size: 1.25rem;
    line-height: 100%;
    letter-spacing: 0em;
    @include s {
      font-size: 1rem;
    }
    @include xs {
      font-size: 0.875rem;
    }
  }
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .counter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 1rem;

    @include xs {
      gap: 0.75rem;
    }
  }
}
.label, .bold, .primary {
  @include MediumFont;
  font-weight: 600;
}
.bold {font-weight: 700;}
.primary { color: $primary-color;}
.line {
  width: 100%;
  border: 1px solid #43435180;
}